import React, { useState } from "react";
import Ftuercas from '../../aym666/galery/999333/AYMCONTRATISTAS999333';
import { Link } from "react-router-dom";
import iconos from '../../icon/iconos';
import './contacto.css';








function Proyectos() {







    const [rightClick, setRightClick] = useState(false);
    const handleContextMenu = (event) => {
        event.preventDefault();
        setRightClick(true);
    };

    const handleItemClick = () => {
        window.scrollTo(0, 0);
    };


    return (

        <section className="contacto__contenedor">





            <div className="contacto__AYM__contenedor">

                <div className='Reterno__links__contacto'>

                    <Link to="/" className="btn__subcontacto__AYM" onClick={handleItemClick}>
                        Inicio
                    </Link>
                    <img src={iconos[8]} alt="Atras" />

                    <span>
                        Contacto
                    </span>

                </div>
                <div className="contacto__box contacto__AYM__contenedor__box1">

                    <div>
                        <h2>Siempre disponibles</h2>
                        <div>
                            <p>Si tienes alguna pregunta o necesitas más información,
                                no dudes en ponerte en contacto con nosotros.<br />
                                ¡Estaremos encantados de ayudarte en lo que necesites!</p>
                        </div>
                    </div>
                </div>


                <div className="contacto__box contacto__AYM__contenedor__box2">

                    <div>

                        <div className="contacto__AYM__contenedor__box2__A subbox">
                            <figure>
                                <img className="contacto__AYM__img" onContextMenu={handleContextMenu} src={Ftuercas[5]} alt="Consultoría Administrativa" />
                                <figcaption className="contacto__AYM__img__figcaption">
                                    <Link to="./solicita-informacion-de-nuestros-servicios" className="btn__contacto__AYM" onClick={handleItemClick}>InfoServicios</Link>
                                </figcaption>
                            </figure>
                            {rightClick && null}
                        </div>


                        <div className="contacto__AYM__contenedor__box2__B subbox">
                            <figure>
                                <img className="contacto__AYM__img" onContextMenu={handleContextMenu} src={Ftuercas[6]} alt="Consultoría Administrativa" />
                                <figcaption className="contacto__AYM__img__figcaption">
                                    <Link to="./atencion-al-cliente" className="btn__contacto__AYM" onClick={handleItemClick}> Atención al cliente</Link>
                                </figcaption>

                            </figure>
                            {rightClick && null}
                        </div>


                        <div className="contacto__AYM__contenedor__box2__C subbox">
                            <figure>
                                <img className="contacto__AYM__img" onContextMenu={handleContextMenu} src={Ftuercas[7]} alt="Consultoría Administrativa" />
                                <figcaption className="contacto__AYM__img__figcaption">
                                    <Link to="./rrhh" className="btn__contacto__AYM" onClick={handleItemClick}> Trabaja con nosotros</Link>

                                </figcaption>
                            </figure>
                            {rightClick && null}
                        </div>



                    </div >
                </div >
            </div >

        </section >


    );
}
export default Proyectos;