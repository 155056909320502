
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useLocation } from 'react-router-dom';
import './home.css';
import IconNav from '../iconNav/iconNav';
import iconHome from '../iconHome/iconHome';
import mp3aym from '../aym666/audio/mp3texto/mp3aym';



function Nosotros() {










    const handleItemClick = () => {
        window.scrollTo(0, 0);
    };






    const [audioPlaying, setAudioPlaying] = useState(false);
    const [audioInstance, setAudioInstance] = useState(null);
    const lastContentRef = useRef(null);

    const playAudio = (index) => {
        if (!audioPlaying) {
            const audio = new Audio(mp3aym[index]);
            audio.play();
            audio.addEventListener('ended', () => {
                setAudioPlaying(false);
            });
            setAudioInstance(audio);
            setAudioPlaying(true);
        } else {
            audioInstance.pause();
            setAudioPlaying(false);
        }
    };
















    const location = useLocation();


    const iconServicios = location.pathname === '/servicios' ||
        location.pathname === '/servicios/consultoria-administrativa' ||
        location.pathname === '/servicios/consultoria-ambiental' ||
        location.pathname === '/servicios/construccion-y-proyectos' ||
        location.pathname === '/servicios/sst' ||
        location.pathname === '/servicios/mantenimiento' ? 2 : 8;

    const iconADM = location.pathname === '/servicios/consultoria-administrativa' ? 13 : 13;
    const iconAmbiental = location.pathname === '/servicios/consultoria-ambiental' ? 14 : 14;
    const iconConstruccion = location.pathname === '/servicios/construccion-y-proyectos' ? 15 : 15;
    const iconSSOMA = location.pathname === '/servicios/sst' ? 16 : 16;
    const iconMantenimiento = location.pathname === '/servicios/sst' ? 17 : 17;

    const iconProductos = location.pathname === '/productos/epps' || location.pathname === '/productos/contenedores-ecologicos' ? 3 : 9;
    const iconEPP = location.pathname === '/productos/epps' ? 18 : 18;
    const iconContenedor = location.pathname === '/productos/contenedores-ecologicos' ? 19 : 19;


    const iconProyectos = location.pathname === '/proyectos' ? 4 : 10;
    const iconBlog = location.pathname === '/blog' ? 5 : 11;
    const iconContacto = location.pathname === '/contacto' ? 6 : 12;



    const iconSrcServicios = IconNav[iconServicios];

    const iconSrcADM = IconNav[iconADM];
    const iconSrcAmbiental = IconNav[iconAmbiental];
    const iconSrcConstruccion = IconNav[iconConstruccion];
    const iconSrcSSOMA = IconNav[iconSSOMA];
    const iconSrcMantenimiento = IconNav[iconMantenimiento];


    const iconSrcProductos = IconNav[iconProductos];
    const iconSrcEPP = IconNav[iconEPP];
    const iconSrcContenedor = IconNav[iconContenedor];

    const iconSrcProyectos = IconNav[iconProyectos];
    const iconSrcBlog = IconNav[iconBlog];
    const iconSrcContacto = IconNav[iconContacto];





    const getLiStyle = (iconIndex, location, activeIndexes) => {
        const liStyle = {
            backgroundImage: 'none',
            backgroundPosition: 'initial',
            backgroundRepeat: 'initial',
            backgroundSize: 'initial',
        };


        if (activeIndexes.includes(iconIndex)) {
            liStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
            liStyle.fontWeight = 'bold';
        } else if (location.pathname === '/') {
            if (iconIndex === 1) {
                liStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
                liStyle.fontWeight = 'bold';
            }


        } else if (location.pathname === '/productos') {
            if (iconIndex === 3) {
                liStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
                liStyle.fontWeight = 'bold';
            }
        } else if (location.pathname === '/proyectos') {
            if (iconIndex === 4) {
                liStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
                liStyle.fontWeight = 'bold';
            }
        } else if (location.pathname === '/blog') {
            if (iconIndex === 5) {
                liStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
                liStyle.fontWeight = 'bold';

            }
        } else if (location.pathname === '/contacto') {
            if (iconIndex === 6) {
                liStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
                liStyle.fontWeight = 'bold';
            }
        }

        return liStyle;
    };

    function getSpanStyle(location) {
        const spanStyle = {};

        const serviciosRoutes = [
            '/servicios/consultoria-administrativa',
            '/servicios/consultoria-ambiental',
            '/servicios/construccion-y-proyectos',
            '/servicios/sst',
            '/servicios/mantenimiento',

        ];

        if (serviciosRoutes.includes(location.pathname)) {
            spanStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
            spanStyle.fontWeight = 'bold';

        }

        return spanStyle;
    }


    function getSpanStyleProductos(location) {
        const spanStyleProductos = {};

        const ProductosRoutes = [
            '/productos/epps',
            '/productos/contenedores-ecologicos',
        ];

        if (ProductosRoutes.includes(location.pathname)) {
            spanStyleProductos.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
            spanStyleProductos.fontWeight = 'bold';

        }

        return spanStyleProductos;
    }




    function getIconStyle(location) {
        const iconStyle = {};

        const serviciosRoutes = [
            '/servicios/consultoria-administrativa',
            '/servicios/consultoria-ambiental',
            '/servicios/construccion-y-proyectos',
            '/servicios/sst',
            '/servicios/mantenimiento',

        ];

        if (serviciosRoutes.includes(location.pathname)) {
            iconStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';


        }

        return iconStyle;
    }


    function getSubListStyle(location, currentRoute) {
        const subListStyle = {};

        if (location.pathname === currentRoute) {
            subListStyle.fontWeight = 'bold';
            subListStyle.background = 'rgba(1, 238, 255, 0.263)';
            subListStyle.paddingLeft = '5px';
            subListStyle.paddingRight = '5px';
            subListStyle.borderRadius = '20px';

        }

        return subListStyle;
    }



    const [isSubMenuVisibleServicios, setSubMenuVisibleServicios] = useState(false);
    const [isSubMenuVisibleProductos, setSubMenuVisibleProductos] = useState(false);

    const serviciosTimerRef = useRef(null);
    const productosTimerRef = useRef(null);

    const resetTimer = useCallback((timerRef, setIsSubMenuVisible) => {
        clearTimeout(timerRef.current);
        setIsSubMenuVisible(true);
        timerRef.current = setTimeout(() => {
            setIsSubMenuVisible(false);
        }, 60000);
    }, []);

    useEffect(() => {
        const serviciosTimer = serviciosTimerRef.current;
        const productosTimer = productosTimerRef.current;

        return () => {
            clearTimeout(serviciosTimer);
            clearTimeout(productosTimer);
        };
    }, []);

    const handleSubMenuToggleServicios = useCallback(() => {
        setSubMenuVisibleServicios((prevState) => !prevState);
        resetTimer(serviciosTimerRef, setSubMenuVisibleServicios);
    }, [resetTimer]);

    const handleSubMenuToggleProductos = useCallback(() => {
        setSubMenuVisibleProductos((prevState) => !prevState);
        resetTimer(productosTimerRef, setSubMenuVisibleProductos);
    }, [resetTimer]);

















    return (
        <section className='AYM__CONTRATISTAS__HOME'>


            <div className='AYM__CONTRATISTAS__SECTION__CONOCENOS'>

                <section className='bloqueaym'>
                    <div className='aym-grid goldh'>

                        <h2 className='BLOQUEAYM__H2'>Acerca de Nosotros</h2>
                        <p className='BLOQUEAYM__P SUBTITLE__AYM'>Conoce los objetivos y perspectivas que impulsan nuestro crecimiento y guían nuestra dirección.</p>

                        <div className='bloqueaym__divking' id='divking'>
                            <div className='bloqueaym__A'>
                                <div className='BLOQUEAYM__SUBCONTENEDOR'>

                                    <div className='bloqueaym__IMG__contenedor'>
                                        <h3 className='BLOQUEAYM__H3'><i className='audio-iconP'>
                                            <img className='BLOQUEAYM__AUDIO__ICON bloqueaym__icon' src={iconHome[4]} alt='icono de audio' title='Reproducir archivo de audio' onClick={() => playAudio(1)} />
                                        </i>Misión</h3>
                                    </div >

                                    <div className='BLOQUEAYM__P__CONTENEDOR'>

                                        <p ref={lastContentRef} className='BLOQUEAYM__P'>
                                            En A&M Técnicos Ingenieros SAC,
                                            nos dedicamos a brindar servicios
                                            integrales de consultoría administrativa,
                                            consultoría ambiental, construcción y proyectos,
                                            seguridad y salud en el trabajo y mantenimiento,
                                            con la más alta calidad y eficacia.
                                            Nuestro compromiso es superar las expectativas
                                            de nuestros clientes,
                                            proporcionándoles un servicio completo
                                            y personalizado
                                            que cubra todas sus necesidades.
                                            Como líderes en el mercado,
                                            nos destacamos por ofrecer
                                            un servicio de excelencia,
                                            basado en el conocimiento
                                            y la experiencia de nuestro equipo,
                                            y en la utilización de tecnologías de vanguardia
                                            para brindar soluciones eficientes y efectivas,
                                            asegurando el cumplimiento de los más
                                            altos estándares de calidad y seguridad
                                            establecidos por las regulaciones
                                            y normativas aplicables en el Perú.
                                        </p>
                                    </div>
                                </div>


                            </div>





                            <div className="bloqueaym__B">


                                <div className="BLOQUEAYM__SUBCONTENEDOR">

                                    <div className='bloqueaym__IMG__contenedor  VISION'>
                                        <h3 className='BLOQUEAYM__H3'><i className='audio-iconP'>
                                            <img className='BLOQUEAYM__AUDIO__ICON bloqueaym__icon' src={iconHome[4]} alt='icono de audio' title='Reproducir archivo de audio' onClick={() => playAudio(2)} />
                                        </i>Visión</h3>
                                    </div>


                                    <div className='BLOQUEAYM__P__CONTENEDOR'>

                                        <p className='BLOQUEAYM__P'>
                                            Nos esforzamos por impulsar el crecimiento
                                            y la transformación de la industria mediante
                                            nuestra visión de liderazgo en la generación
                                            de soluciones innovadoras y de alto impacto
                                            en el mercado peruano. Buscamos consolidarnos
                                            como referentes de excelencia y calidad en
                                            cada una de las áreas de servicios y consultoría
                                            que ofrecemos, garantizando la satisfacción
                                            y fidelidad de nuestros clientes.
                                            Aspiramos a ser la mejor opción en
                                            el mercado, con un enfoque personalizado
                                            y un equipo de profesionales altamente
                                            capacitados en la implementación de
                                            tecnologías de última generación
                                            y buenas prácticas empresariales.
                                            Adicionalmente, nos comprometemos
                                            a ser un agente de cambio social y
                                            ambiental en nuestra sociedad,
                                            trabajando en proyectos de responsabilidad
                                            social y apoyando iniciativas para
                                            el desarrollo sostenible del país
                                            y la mejora del bienestar de las comunidades.</p>

                                    </div>

                                </div>

                            </div>






                        </div>

                    </div>





                    <div className='AYM__CONTRATISTAS__SECTION__RUTAS'>

                        <section className='AYM__CONTRATISTAS__SECTION__RUTAS__CONTENEDOR'>

                            <div className='SECTION__RUTAS__DIV1'>
                                <ul className='UL__AYM__RUTAS'>
                                    <li style={getLiStyle(2, location, [8, 9, 10, 11, 12])} className={`LISTA__SUBUL ${location.pathname.startsWith('/servicios') ? 'active' : ''}`} onClick={handleSubMenuToggleServicios}>

                                        <img style={getIconStyle(location)} className='ICONO__MENU' src={iconSrcServicios} alt='icono servicios' />
                                        <span style={getSpanStyle(location)} className={`SPAN__TOP ${location.pathname.startsWith('/servicios') ? 'active' : ''}`} onClick={handleSubMenuToggleServicios}>Servicios</span>

                                        {isSubMenuVisibleServicios && (
                                            <ul className='SUB__UL servicios'>
                                                <li style={getSubListStyle(location, '/servicios/consultoria-administrativa')}>

                                                    <Link to='/servicios/consultoria-administrativa' aria-label='Este es un enlace al servicio de consultoria-administrativa' onClick={handleItemClick}>
                                                        <img style={getIconStyle(location)} className='ICONO__MENU' src={iconSrcADM} alt='icono servicios' />
                                                        <span>Consultoría Administrativa</span>
                                                    </Link>
                                                </li>

                                                <li style={getSubListStyle(location, '/servicios/consultoria-ambiental')}>
                                                    <Link to='/servicios/consultoria-ambiental' aria-label='Este es un enlace al servicio de consultoria-ambiental' onClick={handleItemClick}>
                                                        <img style={getIconStyle(location)} className='ICONO__MENU' src={iconSrcAmbiental} alt='icono servicios' />
                                                        <span> Consultoría Ambiental</span>
                                                    </Link>
                                                </li>

                                                <li style={getSubListStyle(location, '/servicios/construccion-y-proyectos')}>
                                                    <Link to='/servicios/construccion-y-proyectos' aria-label='Este es un enlace al servicio de construccion-y-proyectos' onClick={handleItemClick}>
                                                        <img style={getIconStyle(location)} className='ICONO__MENU' src={iconSrcConstruccion} alt='icono servicios' />
                                                        <span> Construcción y Proyectos</span>
                                                    </Link>
                                                </li>

                                                <li style={getSubListStyle(location, '/servicios/sst')}>
                                                    <Link to='/servicios/sst' aria-label='Este es un enlace al servicio de seguridad-y-salud-en-el-trabajo' onClick={handleItemClick}>
                                                        <img style={getIconStyle(location)} className='ICONO__MENU' src={iconSrcSSOMA} alt='icono servicios' />
                                                        <span>Seguridad y Salud en el Trabajo</span>
                                                    </Link>
                                                </li>

                                                <li style={getSubListStyle(location, '/servicios/mantenimiento')}>
                                                    <Link to='/servicios/mantenimiento' aria-label='Este es un enlace al servicio de mantenimiento' onClick={handleItemClick}>
                                                        <img style={getIconStyle(location)} className='ICONO__MENU' src={iconSrcMantenimiento} alt='icono servicios' />
                                                        <span>Servicios de Mantenimiento</span>
                                                    </Link>
                                                </li>

                                            </ul>
                                        )}


                                    </li>

                                    <li style={getLiStyle(3, location, [9])} className={`LISTA__SUBUL ${location.pathname.startsWith('/productos') ? 'active' : ''}`} onClick={handleSubMenuToggleProductos}>

                                        <img className='ICONO__MENU' src={iconSrcProductos} alt='icono productos' />

                                        <span style={getSpanStyleProductos(location)} className={`SPAN__TOP ${location.pathname.startsWith('/productos') ? 'active' : ''}`} onClick={handleSubMenuToggleProductos}>Productos</span>

                                        {isSubMenuVisibleProductos && (
                                            <ul className="SUB__UL productos">
                                                <li style={getSubListStyle(location, '/productos/epps')}>

                                                    <Link to="/productos/epps" onClick={handleItemClick}>
                                                        <img className='ICONO__MENU' src={iconSrcEPP} alt='icono productos' />
                                                        <span>Equipos de Protección Personal</span>
                                                    </Link>
                                                </li>

                                                <li style={getSubListStyle(location, '/productos/contenedores-ecologicos')}>
                                                    <Link to="/productos/contenedores-ecologicos" onClick={handleItemClick}>
                                                        <img className='ICONO__MENU' src={iconSrcContenedor} alt='icono productos' />
                                                        <span>Contenedores Ecológicos</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        )}
                                    </li>

                                    <li style={getLiStyle(4, location, [9])} className='LI__LINK'>
                                        <Link to='/proyectos' className='AYM__div__nav__ul__li' onClick={handleItemClick}>
                                            <img className='ICONO__MENU' src={iconSrcProyectos} alt='Icono__Proyectos' />
                                            <span>Proyectos</span>
                                        </Link>
                                    </li>

                                    <li style={getLiStyle(5, location, [11])} className='LI__LINK'>
                                        <Link to='/blog' onClick={handleItemClick}>
                                            <img className='ICONO__MENU' src={iconSrcBlog} alt='icono blog' />
                                            <span>Blog</span>
                                        </Link>
                                    </li>

                                    <li style={getLiStyle(6, location, [12])} className='LI__LINK END'>
                                        <Link to='/contacto' onClick={handleItemClick}>
                                            <img className='ICONO__MENU' src={iconSrcContacto} alt='icono contacto' />
                                            <span>Contacto</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </section>
                    </div>







                </section>



            </div>



























        </section >

    );
}

export default Nosotros;
