
import ZPT01A from './ZPT01A.webp';
import ZPT01B from './ZPT01B.webp';
import ZPT01C from './ZPT01C.webp';
import ZPT01D from './ZPT01D.webp';




const Tuercas = [
    ZPT01A,
    ZPT01B,
    ZPT01C,
    ZPT01D

];
export default Tuercas;