import React, { useState } from 'react';
import "./infoservicios.css";
import { Link } from "react-router-dom";
import iconos from '../../../icon/iconos';
import axios from "axios";

function Contactosub3() {



    const handleItemClick = () => {
        window.scrollTo(0, 0);
    };








    const [nombre, setNombre] = useState("");
    const [email, setEmail] = useState("");
    const [mensaje, setMensaje] = useState("");
    const [enviado, setEnviado] = useState(false);
    const [telefono, setTelefono] = useState("");
    const [telefonoError, setTelefonoError] = useState('');
    const [caracteres, setCaracteres] = useState(0);
    const [caracteresMax] = useState(500);



    const handleSubmit = async (e) => {
        e.preventDefault();
        if (telefono.length !== 9 || !/^9/.test(telefono)) {
            setTelefonoError(<span style={{ color: 'red' }}>Ingrese un número válido</span>);
            return;

        }


        try {
            await axios.post(
                "https://api.sendinblue.com/v3/smtp/email",
                {
                    sender: { name: nombre, email: email },
                    to: [{ email: "gamz.miguel@gmail.com" }],
                    subject: "FORMULARIO DE CONTACTO - AYMCONTRATISTA.COM",
                    htmlContent: `<html><head></head><body><p>NOMBRE: ${nombre}<br>CELULAR: ${telefono}<br>CORREO: ${email}<br><br>${mensaje}</p></body></html>`,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "api-key": "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",
                    },
                }
            );
        } catch (error) {
            console.log(error);
        } finally {
            setEnviado(true);
            // después de 5 segundos, se reinicia el formulario
            setTimeout(() => {
                setEnviado(false);
                setNombre('');
                setEmail('');
                setTelefono('');
                setMensaje('');
                setTelefonoError('');
            }, 2666);
        }
    };




    return (
        <section className='section__infoservicios'>
            <div className='div__infoservicios'>

                <div className='retorno__infoservicios'>

                    <Link to="/" className="btn__subcontacto__AYM" onClick={handleItemClick}>
                        Inicio
                    </Link>
                    <img src={iconos[8]} alt="Atras" />
                    <Link to="/contacto" className="btn__subcontacto__AYM" onClick={handleItemClick}>
                        Contacto
                    </Link>
                    <img src={iconos[8]} alt="Atras" />
                    <span>
                        InfoServicios
                    </span>

                </div>




                <div className='subcontacto__div__box3'>

                    <h2 className='subcontacto__div__box3__h2'>
                        Obtén más información sobre nuestros servicios
                    </h2>

                    <div>
                        <p>Soluciones integrales en servicios de consultoría administrativa, consultoría ambiental,
                            construcción y proyectos, seguridad y salud en el trabajo, y servicios de mantenimiento</p>
                    </div>

                    <br />



                    <div>

                        {!enviado ? (
                            <form className="form__principal" onSubmit={handleSubmit}>
                                <h2 className='formularioh2'>Formulario de información </h2>

                                <label htmlFor="full-name" className="form-label">Nombre completo:<span style={{ color: "red" }}>*</span></label>
                                <input type="text" id="full-name" placeholder="NOMBRES Y APELLIDOS" value={nombre} onChange={(e) => setNombre(e.target.value)} maxLength={40} required />

                                <label htmlFor="email" className="form-label">Email:<span style={{ color: "red" }}>*</span></label>
                                <input type="email" id="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} maxLength={40} required />



                                <label htmlFor="phone" className="form-label">Celular:<span style={{ color: "red" }}>*</span></label>
                                <input
                                    type="phone" id="phone" placeholder="Número de celular" value={telefono} onChange={(event) => {
                                        setTelefono(event.target.value);
                                    }}
                                    maxLength={9}
                                    required
                                    className={`telefono-input ${telefonoError ? 'error' : ''}`}
                                />
                                {telefonoError && <p className='telefono-error'>{telefonoError}</p>}


                                <label htmlFor="mensaje" className="form-label">Mensaje:</label>
                                <textarea
                                    type="mensaje" id="mensaje"
                                    value={mensaje}
                                    onChange={(e) => {
                                        if (e.target.value.length <= caracteresMax) {
                                            setMensaje(e.target.value);
                                            setCaracteres(e.target.value.length);
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13 && e.shiftKey === false) {
                                            e.preventDefault();
                                        }
                                    }}
                                    maxLength={caracteresMax}
                                    required
                                    className="mensaje-textarea"
                                ></textarea>
                                <p className="caracteresp">{caracteres} de {caracteresMax} caracteres máximos.</p>


                                <button type="submit" className="submitb">Enviar</button>
                            </form>
                        ) : (
                            <div>
                                <h3>¡Gracias por contactarnos!</h3>
                            </div>
                        )}
                    </div>




                    <br />



                    <p>
                        En A&M Contratistas, nos enorgullece ofrecer servicios de alta
                        calidad en los sectores en los que nos especializamos.
                        Si necesitas más información sobre nuestros servicios
                        de consultoría, construcción y proyectos, seguridad y
                        salud en el trabajo, o servicios de mantenimiento,
                        no dudes en contactarnos. Estamos aquí para brindarte
                        la información que necesitas para tomar una decisión
                        informada y ayudarte a alcanzar tus objetivos.
                        Contáctanos hoy y descubre cómo podemos ayudarte.
                    </p>

                    <div className='div__politica_privacidad'> <Link to="/politica-de-privacidad" onClick={handleItemClick}>
                        Revisa nuestra Política de Privacidad

                    </Link></div>

                    <br />




                    <div className='subcontacto__div__box3__info'>
                        <ul>
                            <li>
                                <h3>Escríbenos:</h3>
                            </li>

                            <li>
                                <a href="mailto:info@aymcontratista.com">
                                    info@aymcontratista.com
                                </a>
                            </li>



                        </ul>



                        <ul>
                            <li>
                                <h3>Contáctanos por WhatsApp</h3>
                            </li>





                            <li>

                                <a href="https://wa.me/51963245490?text=Atención%20al%20cliente" target="_blank" rel="noreferrer">
                                    <img src={iconos[16]} alt="Atras" />
                                    913 280 309
                                </a>
                            </li>


                        </ul>

                    </div>
                </div>


            </div>






        </section>



    );

}

export default Contactosub3;