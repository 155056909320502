import React, { useState, useEffect } from "react";


import './blog.css';

function Blog() {
    const [articulos, setArticulos] = useState([]);

    useEffect(() => {
        const importarArticulos = async () => {
            const contexto = require.context('./articulos', true, /\.js$/);
            const archivos = contexto.keys();
            const modulos = await Promise.all(archivos.map(async (archivo) => {
                const modulo = await import(`./articulos${archivo.slice(1)}`);
                return modulo.default;
            }));
            setArticulos(modulos);
        };
        importarArticulos();
    }, []);

    return (
        <section className="section__blog">
            {articulos.map((Articulo, index) => (
                <div className="section__blog__articulos" key={index}>
                    <Articulo />
                </div>
            ))}
        </section>
    );
}

export default Blog;