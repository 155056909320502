import CTDS__00 from './CTDS__00.webp';
import CTDS__01 from './CTDS__01.webp';
import CTDS__02 from './CTDS__02.webp';
import CTDS__03 from './CTDS__03.webp';
import CTDS__04 from './CTDS__04.webp';
import CTDS__05 from './CTDS__05.webp';
import CTDS__06 from './CTDS__06.webp';
import CTDS__07 from './CTDS__07.webp';
import CTDS__08 from './CTDS__08.webp';
import CTDS__09 from './CTDS__09.webp';


const ContenedoresIMG = [
    CTDS__00,
    CTDS__01,
    CTDS__02,
    CTDS__03,
    CTDS__04,
    CTDS__05,
    CTDS__06,
    CTDS__07,
	CTDS__08,
	CTDS__09,
];
export default ContenedoresIMG;