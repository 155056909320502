import tcotizacion from './TUERCACOTIZACION.webp';
import Beneficios from './beneficios.webp';
import Administracion from './administracion.webp';
import Ambiental from './ambiental.webp';
import Proyectos from './proyectos.webp';
import Sst from './sst.webp';
import Mantenimiento from './mantenimiento.webp';
import Ambiental3 from './Ambiental3.webp';
import Administracion2 from './Administracion2.webp';


const Tuercas = [
    tcotizacion,
    Beneficios,
    Administracion,
    Ambiental,
    Proyectos,
    Sst,
    Mantenimiento,
    Ambiental3,
    Administracion2
];
export default Tuercas;