import phone from './phone.svg';
import clock from './clock.svg';
import envelope from './envelope.svg';
import linkedin from './linkedin.svg';
import twitter from './twitter.svg';
import facebook from './facebook.svg';
import Logoaymcontratistas from './Logoaymcontratistas.png';
import izquierdac from './izquierda.svg';
import derecha from './derecha.svg';
import gear from './gear.svg';
import play from './play.svg';
import volume from './volume.svg';
import Plus from './plus.svg';
import Minus from './minus.svg';
import Check from './check.svg';
import Down from './down.svg';
import Wsp from './whatsapp.svg';
import Left from './left.svg';
import Customer from './customer.svg';
import Teamwork from './teamwork.svg';
import Folder from './folder.svg';
import Padlock from './padlock.svg';
import Home from './home.svg';




const iconos = [
    phone,
    clock,
    envelope,
    linkedin,
    twitter,
    facebook,
    Logoaymcontratistas,
    izquierdac,
    derecha,
    gear,
    play,
    volume,
    Plus,
    Minus,
    Check,
    Down,
    Wsp,
    Left,
    Customer,
    Teamwork,
    Folder,
    Padlock,
    Home


];

export default iconos;