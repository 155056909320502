import React, { useState, useEffect, useCallback } from "react";
import ProyectosWEBP from '../../recursos/ARRAY/PROYECTOS/ProyectosWEBP';

import './proyectos.css';
const titulosProyectos = [
    'Construcción de instalaciones higiénicas',
    'Diseño y construcción de cocina',
    'Reparación de tejados',
    'Construcción de viviendas',
    'Soldadura de estructuras metálicas',
    'Mantenimiento preventivo y correctivo de luminarias',
    'Reparaciones eléctricas',
    'Mantenimiento de aire acondicionado'



    // Agrega más títulos según sea necesario
];




function Proyectos() {


    const [activeImageIndex, setActiveImageIndex] = useState(null);

    const openViewer = (index) => {
        setActiveImageIndex(index);
    };

    const closeViewer = () => {
        setActiveImageIndex(null);
    };


    const handleKeyPress = useCallback((event) => {
        if (event.key === "Escape") {
            closeViewer();
        }
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            handleKeyPress(event);
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyPress]);


    return (
        <section className="section__proyectosAYM">
            <div className="proyectos-grid">
                {ProyectosWEBP.map((proyecto, index) => (
                    <div className="proyecto" key={index}>
                        <img
                            className="proyecto-img"
                            onClick={() => openViewer(index)}
                            src={proyecto}
                            alt={`Proyecto ${index + 1}`}
                        />
                        <h3 className="proyecto-title">{titulosProyectos[index]}</h3>
                    </div>
                ))}
            </div>



            {activeImageIndex !== null && (
                <div className="image-viewer" onClick={closeViewer}>
                    <div className="image-viewer-content">
                        <img src={ProyectosWEBP[activeImageIndex]} alt={`Proyecto ${activeImageIndex + 1}`} />
                    </div>
                </div>
            )}
        </section>
    );
}

export default Proyectos;
