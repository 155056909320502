import Iso__00__9001 from './Iso__00__9001.webp';
import Iso__01__14001 from './Iso__01__14001.webp';
import Iso__02__37001 from './Iso__02__37001.webp';
import Iso__03__45001 from './Iso__03__45001.webp';


const Isoimg = [
    Iso__00__9001,
    Iso__01__14001,
    Iso__02__37001,
    Iso__03__45001



];

export default Isoimg;