import mp30 from './AYM0000.mp3';
import mp31 from './AYM0001.mp3';
import mp32 from './AYM0002.mp3';
import mp33 from './AYM0003.mp3';
import mp34 from './AYM0004.mp3';
import mp35 from './AYM0005.mp3';

const mp3aym = [
    mp30, mp31, mp32, mp33, mp34, mp35,
];
export default mp3aym;
