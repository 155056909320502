import AYM__00__LogoPrincipal from './AYM__00__LogoPrincipal.svg';
import AYM__01__Inicio from './AYM__01__Inicio.svg';
import AYM__02__Servicios from './AYM__02__Servicios.svg';
import AYM__03__Productos from './AYM__03__Productos.svg';
import AYM__04__Proyectos from './AYM__04__Proyectos.svg';
import AYM__05__Blog from './AYM__05__Blog.svg';
import AYM__06__Contacto from './AYM__06__Contacto.svg';
import AYM__07__Inicio__white from './AYM__07__Inicio__white.svg';
import AYM__08__Servicios__white from './AYM__08__Servicios__white.svg';
import AYM__09__Productos__white from './AYM__09__Productos__white.svg';
import AYM__10__Proyectos__white from './AYM__10__Proyectos__white.svg';
import AYM__11__Blog__white from './AYM__11__Blog__white.svg';
import AYM__12__Contacto__white from './AYM__12__Contacto__white.svg';
import AYM__13__Administracion from './AYM__13__Administracion.svg';
import AYM__14__Ambiental from './AYM__14__Ambiental.svg';
import AYM__15__Construccion from './AYM__15__Construccion.svg';
import AYM__16__SSOMA from './AYM__16__SSOMA.svg';
import AYM__17__Mantenimiento from './AYM__17__Mantenimiento.svg';
import AYM__18__EPP from './AYM__18__EPP.svg';
import AYM__19__Contenedor from './AYM__19__Contenedor.svg';
import AYM__20__Barra from './AYM__20__Barra.svg';
import AYM__21__Phone from './AYM__21__Phone.svg';
import AYM__22__Mail from './AYM__22__Mail.svg';



const IconNav = [
    AYM__00__LogoPrincipal,
    AYM__01__Inicio,
    AYM__02__Servicios,
    AYM__03__Productos,
    AYM__04__Proyectos,
    AYM__05__Blog,
    AYM__06__Contacto,
    AYM__07__Inicio__white,
    AYM__08__Servicios__white,
    AYM__09__Productos__white,
    AYM__10__Proyectos__white,
    AYM__11__Blog__white,
    AYM__12__Contacto__white,
    AYM__13__Administracion,
    AYM__14__Ambiental,
    AYM__15__Construccion,
    AYM__16__SSOMA,
    AYM__17__Mantenimiento,
    AYM__18__EPP,
    AYM__19__Contenedor,
    AYM__20__Barra,
    AYM__21__Phone,
    AYM__22__Mail,














];

export default IconNav;