
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useLocation } from 'react-router-dom';
import './home.css';
import LogoAYM from '../aym666/galery/Logo/AYM__LogoPrincipal__100.webp';
import LogoAYM80 from '../aym666/galery/Logo/LOGOAYM180.webp';
import svgBandera from '../galery/Bandera.svg';
import iconHome from '../iconHome/iconHome';
import Ftuercas from '../aym666/galery/999333/AYMCONTRATISTAS999333';

import Presentacion from '../videos/landing-page-home/Presentaciom-Aymcontratistas.mp4';

import loadingTuerca from '../galery/tuerca.svg';
import Tuercas from '../aym666/galery/tuercas/tuercas';
import axios from 'axios';
import IconNav from '../iconNav/iconNav';
import Isoimg from '../recursos/JPG/ISO';


function Home() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);





    useEffect(() => {
        let currentIndex = 0;
        const slides = document.querySelectorAll('.slide');
        const nextBtn = document.querySelector('.nextBtn');
        const prevBtn = document.querySelector('.prevBtn');
        slides[currentIndex].classList.add('active');

        const nextSlide = () => {
            slides[currentIndex].classList.remove('active');
            currentIndex = (currentIndex + 1) % slides.length;
            slides[currentIndex].classList.add('active');
        };

        const prevSlide = () => {
            slides[currentIndex].classList.remove('active');
            currentIndex = (currentIndex + slides.length - 1) % slides.length;
            slides[currentIndex].classList.add('active');
        };

        nextBtn.addEventListener('click', nextSlide);
        prevBtn.addEventListener('click', prevSlide);

        const interval = setInterval(nextSlide, 3666);

        function limitSize() {
            if (window.innerWidth < 300) {
                document.body.style.width = '300px';
            } else {
                document.body.style.width = '100%';
            }
        }

        window.addEventListener('resize', limitSize);
        limitSize();

        return () => {
            nextBtn.removeEventListener('click', nextSlide);
            prevBtn.removeEventListener('click', prevSlide);
            clearInterval(interval);
            window.removeEventListener('resize', limitSize);
        };
    }, []);





    const handleItemClick = () => {
        window.scrollTo(0, 0);
    };



    const [rightClick, setRightClick] = useState(false);
    const handleContextMenu = (event) => {
        event.preventDefault();
        setRightClick(true);
    };












    const videoRef = useRef(null);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    const togglePlay = () => {
        if (videoRef.current.paused) {
            videoRef.current.play();
        } else {
            videoRef.current.pause();
        }
    };

    const handleLoadedData = () => {
        setIsVideoLoaded(true);
    };

    const handleVideoEnded = () => {
        setIsVideoLoaded(false);

    };

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener('loadeddata', handleLoadedData);
            videoRef.current.addEventListener('ended', handleVideoEnded);
            return () => {


            };
        }
    }, []);













    const [nombre, setNombre] = useState('');
    const [email, setEmail] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [enviado, setEnviado] = useState(false);
    const [telefono, setTelefono] = useState('');
    const [telefonoError, setTelefonoError] = useState('');
    const [caracteres, setCaracteres] = useState(0);
    const [caracteresMax] = useState(500);



    const handleSubmit = async (e) => {
        e.preventDefault();
        if (telefono.length !== 9 || !/^9/.test(telefono)) {
            setTelefonoError(<span style={{ color: 'red' }}>Ingrese un número válido</span>);
            return;

        }


        try {
            await axios.post(
                'https://api.sendinblue.com/v3/smtp/email',
                {
                    sender: { name: nombre, email: email },
                    to: [{ email: 'gamz.miguel@gmail.com' }],
                    subject: 'FORMULARIO DE CONTACTO - AYMCONTRATISTA.COM',
                    htmlContent: `<html><head></head><body><p>NOMBRE: ${nombre}<br>CELULAR: ${telefono}<br>CORREO: ${email}<br><br>${mensaje}</p></body></html>`,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'api-key': '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',
                    },
                }
            );
        } catch (error) {
            console.log(error);
        } finally {
            setEnviado(true);
            // después de 5 segundos, se reinicia el formulario
            setTimeout(() => {
                setEnviado(false);
                setNombre('');
                setEmail('');
                setTelefono('');
                setMensaje('');
                setTelefonoError('');
            }, 2666);
        }
    };






































    const location = useLocation();


    const iconServicios = location.pathname === '/servicios' ||
        location.pathname === '/servicios/consultoria-administrativa' ||
        location.pathname === '/servicios/consultoria-ambiental' ||
        location.pathname === '/servicios/construccion-y-proyectos' ||
        location.pathname === '/servicios/sst' ||
        location.pathname === '/servicios/mantenimiento' ? 2 : 8;

    const iconADM = location.pathname === '/servicios/consultoria-administrativa' ? 13 : 13;
    const iconAmbiental = location.pathname === '/servicios/consultoria-ambiental' ? 14 : 14;
    const iconConstruccion = location.pathname === '/servicios/construccion-y-proyectos' ? 15 : 15;
    const iconSSOMA = location.pathname === '/servicios/sst' ? 16 : 16;
    const iconMantenimiento = location.pathname === '/servicios/sst' ? 17 : 17;

    const iconProductos = location.pathname === '/productos/epps' || location.pathname === '/productos/contenedores-ecologicos' ? 3 : 9;
    const iconEPP = location.pathname === '/productos/epps' ? 18 : 18;
    const iconContenedor = location.pathname === '/productos/contenedores-ecologicos' ? 19 : 19;


    const iconProyectos = location.pathname === '/proyectos' ? 4 : 10;
    const iconBlog = location.pathname === '/blog' ? 5 : 11;
    const iconContacto = location.pathname === '/contacto' ? 6 : 12;



    const iconSrcServicios = IconNav[iconServicios];

    const iconSrcADM = IconNav[iconADM];
    const iconSrcAmbiental = IconNav[iconAmbiental];
    const iconSrcConstruccion = IconNav[iconConstruccion];
    const iconSrcSSOMA = IconNav[iconSSOMA];
    const iconSrcMantenimiento = IconNav[iconMantenimiento];


    const iconSrcProductos = IconNav[iconProductos];
    const iconSrcEPP = IconNav[iconEPP];
    const iconSrcContenedor = IconNav[iconContenedor];

    const iconSrcProyectos = IconNav[iconProyectos];
    const iconSrcBlog = IconNav[iconBlog];
    const iconSrcContacto = IconNav[iconContacto];





    const getLiStyle = (iconIndex, location, activeIndexes) => {
        const liStyle = {
            backgroundImage: 'none',
            backgroundPosition: 'initial',
            backgroundRepeat: 'initial',
            backgroundSize: 'initial',
        };


        if (activeIndexes.includes(iconIndex)) {
            liStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
            liStyle.fontWeight = 'bold';
        } else if (location.pathname === '/') {
            if (iconIndex === 1) {
                liStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
                liStyle.fontWeight = 'bold';
            }


        } else if (location.pathname === '/productos') {
            if (iconIndex === 3) {
                liStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
                liStyle.fontWeight = 'bold';
            }
        } else if (location.pathname === '/proyectos') {
            if (iconIndex === 4) {
                liStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
                liStyle.fontWeight = 'bold';
            }
        } else if (location.pathname === '/blog') {
            if (iconIndex === 5) {
                liStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
                liStyle.fontWeight = 'bold';

            }
        } else if (location.pathname === '/contacto') {
            if (iconIndex === 6) {
                liStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
                liStyle.fontWeight = 'bold';
            }
        }

        return liStyle;
    };

    function getSpanStyle(location) {
        const spanStyle = {};

        const serviciosRoutes = [
            '/servicios/consultoria-administrativa',
            '/servicios/consultoria-ambiental',
            '/servicios/construccion-y-proyectos',
            '/servicios/sst',
            '/servicios/mantenimiento',

        ];

        if (serviciosRoutes.includes(location.pathname)) {
            spanStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
            spanStyle.fontWeight = 'bold';

        }

        return spanStyle;
    }


    function getSpanStyleProductos(location) {
        const spanStyleProductos = {};

        const ProductosRoutes = [
            '/productos/epps',
            '/productos/contenedores-ecologicos',
        ];

        if (ProductosRoutes.includes(location.pathname)) {
            spanStyleProductos.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
            spanStyleProductos.fontWeight = 'bold';

        }

        return spanStyleProductos;
    }




    function getIconStyle(location) {
        const iconStyle = {};

        const serviciosRoutes = [
            '/servicios/consultoria-administrativa',
            '/servicios/consultoria-ambiental',
            '/servicios/construccion-y-proyectos',
            '/servicios/sst',
            '/servicios/mantenimiento',

        ];

        if (serviciosRoutes.includes(location.pathname)) {
            iconStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';


        }

        return iconStyle;
    }


    function getSubListStyle(location, currentRoute) {
        const subListStyle = {};

        if (location.pathname === currentRoute) {
            subListStyle.fontWeight = 'bold';
            subListStyle.background = 'rgba(1, 238, 255, 0.263)';
            subListStyle.paddingLeft = '5px';
            subListStyle.paddingRight = '5px';
            subListStyle.borderRadius = '20px';

        }

        return subListStyle;
    }



    const [isSubMenuVisibleServicios, setSubMenuVisibleServicios] = useState(false);
    const [isSubMenuVisibleProductos, setSubMenuVisibleProductos] = useState(false);

    const serviciosTimerRef = useRef(null);
    const productosTimerRef = useRef(null);

    const resetTimer = useCallback((timerRef, setIsSubMenuVisible) => {
        clearTimeout(timerRef.current);
        setIsSubMenuVisible(true);
        timerRef.current = setTimeout(() => {
            setIsSubMenuVisible(false);
        }, 60000);
    }, []);

    useEffect(() => {
        const serviciosTimer = serviciosTimerRef.current;
        const productosTimer = productosTimerRef.current;

        return () => {
            clearTimeout(serviciosTimer);
            clearTimeout(productosTimer);
        };
    }, []);

    const handleSubMenuToggleServicios = useCallback(() => {
        setSubMenuVisibleServicios((prevState) => !prevState);
        resetTimer(serviciosTimerRef, setSubMenuVisibleServicios);
    }, [resetTimer]);

    const handleSubMenuToggleProductos = useCallback(() => {
        setSubMenuVisibleProductos((prevState) => !prevState);
        resetTimer(productosTimerRef, setSubMenuVisibleProductos);
    }, [resetTimer]);















    return (
        <section className='AYM__CONTRATISTAS__HOME'>
            <div className='AYM__CONTRATISTAS__CONTACTOBARRA'>
                <span><img className='info-icon' src={IconNav[21]} alt='icon' />913 280 309</span>
                <span><img className='info-icon' src={IconNav[22]} alt='icon' />info@aymcontratista.com</span>
            </div>
            <div className='AYM__CONTRATISTAS__SECTION__HOME primario'>


                <div className='AYM__CONTRATISTAS__DIV__CONTENEDOR__HOME primario'>




                    <h1 className='AYM__H1__CONTENEDOR__TITLE' >
                        <span className='AYM__H1__TITLE AYM__BLOCK line'>
                            A&M TÉCNICOS INGENIEROS S.A.C.
                        </span>
                        <span className='AYM__H1__TITLE AYM__BLOCK resaltar f'>
                            Soluciones Profesionales
                        </span>
                        <span className='AYM__H1__TITLE AYM__BLOCK resaltar f'>
                            Técnicas y de Ingeniería
                        </span>

                    </h1>



                    <img className='AYM__IMG__CONTENEDOR__TITLE' src={svgBandera} onContextMenu={handleContextMenu} alt='engranajes AYM CONTRATISTAS' title='ENGRANAJES A&M TECNICOS INGENIEROS SAC' />

                    <div className='AYM__IMG__CONTENEDOR'>
                        <img className='AYM__IMG__LOGOAYM principal' src={windowWidth < 780 ? LogoAYM80 : LogoAYM} onContextMenu={handleContextMenu} alt='Logo AYM CONTRATISTAS' title='Logo A&M TECNICOS INGENIEROS SAC' />
                        <img className='AYM__IMG__ENGRANAJES__LOGOAYM' src={loadingTuerca} onContextMenu={handleContextMenu} alt='engranajes AYM CONTRATISTAS' title='ENGRANAJES A&M TECNICOS INGENIEROS SAC' />





                    </div>

                    <div className='AYM__IMG__SUBCONTENEDOR'>
                        <img className="ISOIMG" src={Isoimg[0]} onContextMenu={handleContextMenu} alt='ISO' title='ISO9001' />
                        <img className="ISOIMG" src={Isoimg[1]} onContextMenu={handleContextMenu} alt='ISO' title='ISO14001' />
                        <img className="ISOIMG" src={Isoimg[2]} onContextMenu={handleContextMenu} alt='ISO' title='ISO37001' />
                        <img className="ISOIMG" src={Isoimg[3]} onContextMenu={handleContextMenu} alt='ISO' title='ISO45001' />

                    </div>








                </div>






            </div>












            <div className='AYM__CONTRATISTAS__DIV__slide'>

                <section className='contenedor__slider'>
                    <div className='slider'>
                        <div className='slide slide1 fade'>

                            <div className='slide-content'>
                                <h2>Consultoría Administrativa</h2>
                                <p>Estamos comprometidos en brindar un servicio de calidad a nuestros clientes</p>
                                <Link to="/servicios/consultoria-administrativa" aria-label='Este es un enlace al servicio de consultoria-administrativa' title='Consultoría administrativa - Más información' data-lbl="servicios-consultoria-administrativa" rel="noreferrer" className="btn" onClick={handleItemClick}>Descubre más</Link>
                            </div>
                        </div>
                        <div className='slide slide2 fade'>

                            <div className='slide-content'>
                                <h2>Consultoría Ambiental</h2>
                                <p>Soluciones efectivas para cumplir con todas las regulaciones ambientales</p>
                                <Link to="/servicios/consultoria-ambiental" aria-label='Este es un enlace al servicio de consultoria-ambiental' title='Consultoría ambiental - Más información' data-lbl="servicios-consultoria-ambiental" rel="noreferrer" className="btn" onClick={handleItemClick}>Descubre más</Link>
                            </div>
                        </div>
                        <div className='slide slide3 fade'>

                            <div className="slide-content">
                                <h2>Construcción & Proyectos</h2>
                                <p>Transformamos ideas en proyectos exitosos con eficiencia y calidad</p>
                                <Link to='/servicios/construccion-y-proyectos' aria-label='Este es un enlace al servicio de construccion-y-proyectos' title='Construcción y proyectos - Más información' data-lbl="servicios-construccion-y-proyectos" rel="noreferrer" className='btn' onClick={handleItemClick}>Descubre más</Link>
                            </div>
                        </div>
                        <div className='slide slide4 fade'>

                            <div className='slide-content'>
                                <h2>Seguridad & Salud en el Trabajo</h2>
                                <p>Garantizamos la seguridad y bienestar de su equipo con nuestros servicios integrales</p>
                                <Link to='/servicios/sst' aria-label='Este es un enlace al servicio de seguridad-y-salud-en-el-trabajo' title='seguridad y salud en el trabajo - Más información' data-lbl="servicios-sst" rel="noreferrer" className='btn' onClick={handleItemClick}>Descubre más</Link>
                            </div>
                        </div>
                        <div className='slide slide5 fade'>

                            <div className='slide-content'>
                                <h2>Mantenimiento Industrial</h2>
                                <p>Contamos con el mejor personal técnico con experiencia garantizada</p>
                                <Link to='/servicios/mantenimiento' aria-label='Este es un enlace al servicio de mantenimiento' title='mantenimiento - Más información' data-lbl="servicios-mantenimiento" rel="noreferrer" className='btn' onClick={handleItemClick}>Descubre más</Link>
                            </div>
                        </div>

                    </div>

                    <div className='slider__arrow__contenedor'>
                        <button className='slider-arrow slider-arrow-left prevBtn'>
                            <img src={iconHome[0]} alt='Flecha Izquierda' title='Retroceder' />

                        </button>


                        <button className='slider-arrow slider-arrow-right nextBtn'>
                            <img src={iconHome[1]} alt='Flecha Derecha' title='Continuar' />
                        </button>

                    </div>

                </section>


            </div>



























































            <div className='AYM__CONTRATISTAS__SECTION__SERVICIOS'>

                <h1 className='titlex AYMH1'>A&M CONTRATISTAS</h1>

                <p className='AYMH1__P' id='contentx-0'>
                    Ofrecemos <span className='AYMH1__span'> soluciones profesionales </span>
                    en los siguientes servicios:
                </p>


                <div className='AYM__CONTRATISTAS__DIV__SERVICIOS'>

                    <div className='AYM__CONTRATISTAS__DIV__CONTENEDOR__SERVICIOS'>

                        <div className='AYM__CONTRATISTAS__CONTENEDOR__TUERCAS top'>

                            <div>
                                <Link to='/servicios/consultoria-administrativa' aria-label='Este es un enlace al servicio de consultoria-administrativa' title='Consultoría Administrativa - Más información' className='F4-a' onClick={handleItemClick}>

                                    <img className='F4-img imgtuercas' onContextMenu={handleContextMenu} src={Ftuercas[0]} alt='Consultoría Administrativa' />
                                    {rightClick && null}
                                    <span className="F4-h3">Consultoría Administrativa</span>

                                </Link>
                            </div>

                            <div>
                                <Link to='/servicios/consultoria-administrativa' aria-label='Este es un enlace al servicio de consultoria-administrativa' title='Consultoría Administrativa - Más información' className="F4-a" onClick={handleItemClick} >
                                    <p className="goldP">
                                        <img className='info-icon icon-roted' src={iconHome[3]} alt='icon' />
                                        Transforme la gestión de su empresa
                                        y logre un mayor éxito financiero
                                        con nuestros servicios de
                                        consultoría administrativa.
                                    </p>
                                </Link>
                            </div>


                        </div>



                        <div className='AYM__CONTRATISTAS__CONTENEDOR__TUERCAS'>
                            <div>
                                <Link to="/servicios/consultoria-ambiental" aria-label='Este es un enlace al servicio de consultoria-ambiental' title='Consultoría Ambiental - Más información' className="F4-a" onClick={handleItemClick} >

                                    <img className="F4-img imgtuercas" onContextMenu={handleContextMenu} src={Ftuercas[1]} alt="Consultoría Ambiental" />
                                    {rightClick && null}
                                    <span className="F4-h3">Consultoría ambiental</span>

                                </Link>
                            </div>

                            <div>
                                <Link to="/servicios/consultoria-ambiental" aria-label='Este es un enlace al servicio de consultoria-ambiental' title='Consultoría Ambiental - Más información' className="F4-a" onClick={handleItemClick}>
                                    <p className="goldP">
                                        <img className="info-icon icon-roted" src={iconHome[3]} alt="icon" />
                                        Asegúrese de cumplir con las regulaciones ambientales
                                        y maximice la sostenibilidad de su empresa
                                        con nuestros servicios de consultoría ambiental.
                                    </p>
                                </Link>
                            </div>

                        </div>


                        <div className='AYM__CONTRATISTAS__CONTENEDOR__TUERCAS'>
                            <div>
                                <Link to="/servicios/construccion-y-proyectos" aria-label='Este es un enlace al servicio de construccion-y-proyectos' title='Construccion y proyectos - Más información' className="F4-a" onClick={handleItemClick}>

                                    <img className="F4-img imgtuercas" onContextMenu={handleContextMenu} src={Ftuercas[2]} alt="Construcción y Proyectos" />
                                    {rightClick && null}
                                    <span className="F4-h3">Construcción y Proyectos</span>


                                </Link>
                            </div>

                            <div>
                                <Link to="/servicios/construccion-y-proyectos" aria-label='Este es un enlace al servicio de construccion-y-proyectos' title='Construccion y proyectos - Más información' className="F4-a" onClick={handleItemClick}>
                                    <p className="goldP">
                                        <img className="info-icon icon-roted" src={iconHome[3]} alt="icon" />
                                        Nos aseguramos de que cada proyecto sea un éxito,
                                        gracias a nuestra experiencia en gestión de proyectos
                                        y a nuestra atención al detalle inigualable.
                                    </p>
                                </Link>
                            </div>
                        </div>


                        <div className='AYM__CONTRATISTAS__CONTENEDOR__TUERCAS'>
                            <div>
                                <Link to="/servicios/sst" aria-label='Este es un enlace al servicio de seguridad-y-salud-en-el-trabajo' title='Seguridad y salud en el trabajo - Más información' className="F4-a" onClick={handleItemClick}>

                                    <img className="F4-img imgtuercas" onContextMenu={handleContextMenu} src={Ftuercas[3]} alt="Seguridad y Salud en el Trabajo" />
                                    {rightClick && null}
                                    <span className="F4-h3">Seguridad y Salud en el Trabajo</span>

                                </Link>
                            </div>
                            <div>
                                <Link to="/servicios/sst" aria-label='Este es un enlace al servicio de seguridad-y-salud-en-el-trabajo' title='Seguridad y salud en el trabajo - Más información' className="F4-a" onClick={handleItemClick}>
                                    <p className="goldP">
                                        <img className="info-icon icon-roted" src={iconHome[3]} alt="icon" />
                                        Prevenir accidentes laborales no es una opción,
                                        es una obligación. Garantiza la seguridad y salud
                                        de tu equipo de trabajo con nuestros servicios especializados
                                    </p>
                                </Link>
                            </div>
                        </div>



                        <div className="AYM__CONTRATISTAS__CONTENEDOR__TUERCAS CENTER">
                            <div>
                                <Link to="/servicios/mantenimiento" className="F4-a" onClick={handleItemClick}>

                                    <img className="F4-img imgtuercas" aria-label='Este es un enlace al servicio de mantenimiento' title='Mantenimiento - Más información' onContextMenu={handleContextMenu} src={Ftuercas[4]} alt="Servicios de Mantenimiento" />
                                    {rightClick && null}
                                    <span className="F4-h3">Servicios de Mantenimiento</span>

                                </Link>
                            </div>

                            <div>
                                <Link to="/servicios/mantenimiento" aria-label='Este es un enlace al servicio de mantenimiento' title='Mantenimiento - Más información' className="F4-a" onClick={handleItemClick}>
                                    <p className="goldP">
                                        <img className="info-icon icon-roted" src={iconHome[3]} alt="icon" />
                                        Ofrecemos servicios de mantenimiento
                                        y reparación a diversos sectores,
                                        incluyendo la industria,
                                        el comercio y los hogares,
                                        a través de nuestro equipo técnico
                                        altamente capacitado y experimentado
                                    </p>
                                </Link>
                            </div>



                        </div>
                    </div>

                </div>
            </div>





























            <div className='AYM__CONTRATISTAS__SECTION__VIDEO'>

                <section className='AYM__CONTRATISTAS__SECTION__CONTENEDOR'>



                    <div className='video-container'>
                        <img
                            src={loadingTuerca}
                            alt='Cargando video...'
                            className={`loading ${isVideoLoaded ? 'hidden' : ''}`}
                            aria-live="polite"
                        />
                        <video

                            ref={videoRef}
                            className={`videoaym ${isVideoLoaded ? '' : 'hidden'}`}
                            onClick={togglePlay}
                            title='Video: Presentación de servicios A&M CONTRATISTAS'
                        >
                            <source src={Presentacion} type="video/mp4" title='Presentación de la empresa' />
                        </video>
                    </div>



                </section>








            </div>









            <div className='AYM__CONTRATISTAS__SECTION__DIAGNOSTICO'>

                <section className='AYM__CONTRATISTAS__SECTION__DIAGNOSTICO__CONTENEDOR'>

                    <div className='SECTION__DIAGNOSTICO__DIV1'>

                        <h2 className='titlex AYMH1'>Diagnóstico gratuito</h2>

                        <p className='goldP' id='contentx-0'>
                            Visitamos tu empresa y/o proyecto
                        </p>
                    </div>


                    <div className='SECTION__DIAGNOSTICO__DIV2'>

                        <h2 className='titlex AYMH1'>Solicita tu cotización personalizada</h2>

                        <p className='goldP BanersAYMP1__p' id='contentx-0'>
                            Si quieres una cotización para nuestros servicios,
                            contáctanos y te haremos una propuesta ajustada
                            a tus necesidades. Permítenos ayudarte a alcanzar tus metas.
                        </p>
                    </div>

                    <div className='SECTION__DIAGNOSTICO__DIV3'>
                        <img src={Tuercas[0]}
                            alt='COTIZACION CON A&M CONTRATISTAS'
                            title="Imagen de la sección de cotización con A&M CONTRATISTAS"
                            onContextMenu={handleContextMenu}
                            className={rightClick ? 'right-click' : ''}
                        />
                        <h2>COTIZA AHORA</h2>
                    </div>

                </section>




            </div>






            <div className='AYM__CONTRATISTAS__SECTION__CONTACTO'>

                <section className='AYM__CONTRATISTAS__SECTION__CONTACTO__CONTENEDOR'>

                    <div className='AYM__CONTRATISTAS__DIV__CONTACTO__CONTENEDOR'>

                        {!enviado ? (
                            <form className='form__principal' onSubmit={handleSubmit}>
                                <h2 className='formularioh2'>Contáctenos </h2>

                                <label htmlFor='full-name' className='form-label'>Nombre completo:<span style={{ color: 'red' }}>*</span></label>
                                <input type='text' id='full-name' placeholder='NOMBRES Y APELLIDOS' value={nombre} onChange={(e) => setNombre(e.target.value)} maxLength={40} required />

                                <label htmlFor='email' className='form-label'>Email:<span style={{ color: 'red' }}>*</span></label>
                                <input type='email' id='email' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} maxLength={40} required />



                                <label htmlFor='phone' className='form-label'>Celular:<span style={{ color: 'red' }}>*</span></label>
                                <input
                                    type='phone' id='phone' placeholder='Número de celular' value={telefono} onChange={(event) => {
                                        setTelefono(event.target.value);
                                    }}
                                    maxLength={9}
                                    required
                                    className={`telefono-input ${telefonoError ? 'error' : ''}`}
                                />
                                {telefonoError && <p className='telefono-error'>{telefonoError}</p>}


                                <label htmlFor="mensaje" className="form-label">Mensaje:</label>
                                <textarea
                                    type="mensaje" id="mensaje"
                                    value={mensaje}
                                    onChange={(e) => {
                                        if (e.target.value.length <= caracteresMax) {
                                            setMensaje(e.target.value);
                                            setCaracteres(e.target.value.length);
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13 && e.shiftKey === false) {
                                            e.preventDefault();
                                        }
                                    }}
                                    maxLength={caracteresMax}
                                    required
                                    className="mensaje-textarea"
                                ></textarea>
                                <p className="caracteresp">{caracteres} de {caracteresMax} caracteres máximos.</p>


                                <button type="submit" className="submitb">Enviar</button>
                            </form>
                        ) : (
                            <div>
                                <h3>¡Gracias por contactarnos!</h3>
                            </div>
                        )}
                    </div>

                </section>

            </div>




            <div className='AYM__CONTRATISTAS__SECTION__RUTAS'>

                <section className='AYM__CONTRATISTAS__SECTION__RUTAS__CONTENEDOR'>

                    <div className='SECTION__RUTAS__DIV1'>
                        <ul className='UL__AYM__RUTAS'>
                            <li style={getLiStyle(2, location, [8, 9, 10, 11, 12])} className={`LISTA__SUBUL ${location.pathname.startsWith('/servicios') ? 'active' : ''}`} onClick={handleSubMenuToggleServicios}>

                                <img style={getIconStyle(location)} className='ICONO__MENU' src={iconSrcServicios} alt='icono servicios' />
                                <span style={getSpanStyle(location)} className={`SPAN__TOP ${location.pathname.startsWith('/servicios') ? 'active' : ''}`} onClick={handleSubMenuToggleServicios}>Servicios</span>

                                {isSubMenuVisibleServicios && (
                                    <ul className='SUB__UL servicios'>
                                        <li style={getSubListStyle(location, '/servicios/consultoria-administrativa')}>

                                            <Link to='/servicios/consultoria-administrativa' aria-label='Este es un enlace al servicio de consultoria-administrativa' onClick={handleItemClick}>
                                                <img style={getIconStyle(location)} className='ICONO__MENU' src={iconSrcADM} alt='icono servicios' />
                                                <span>Consultoría Administrativa</span>
                                            </Link>
                                        </li>

                                        <li style={getSubListStyle(location, '/servicios/consultoria-ambiental')}>
                                            <Link to='/servicios/consultoria-ambiental' aria-label='Este es un enlace al servicio de consultoria-ambiental' onClick={handleItemClick}>
                                                <img style={getIconStyle(location)} className='ICONO__MENU' src={iconSrcAmbiental} alt='icono servicios' />
                                                <span> Consultoría Ambiental</span>
                                            </Link>
                                        </li>

                                        <li style={getSubListStyle(location, '/servicios/construccion-y-proyectos')}>
                                            <Link to='/servicios/construccion-y-proyectos' aria-label='Este es un enlace al servicio de construccion-y-proyectos' onClick={handleItemClick}>
                                                <img style={getIconStyle(location)} className='ICONO__MENU' src={iconSrcConstruccion} alt='icono servicios' />
                                                <span> Construcción y Proyectos</span>
                                            </Link>
                                        </li>

                                        <li style={getSubListStyle(location, '/servicios/sst')}>
                                            <Link to='/servicios/sst' aria-label='Este es un enlace al servicio de seguridad-y-salud-en-el-trabajo' onClick={handleItemClick}>
                                                <img style={getIconStyle(location)} className='ICONO__MENU' src={iconSrcSSOMA} alt='icono servicios' />
                                                <span>Seguridad y Salud en el Trabajo</span>
                                            </Link>
                                        </li>

                                        <li style={getSubListStyle(location, '/servicios/mantenimiento')}>
                                            <Link to='/servicios/mantenimiento' aria-label='Este es un enlace al servicio de mantenimiento' onClick={handleItemClick}>
                                                <img style={getIconStyle(location)} className='ICONO__MENU' src={iconSrcMantenimiento} alt='icono servicios' />
                                                <span>Servicios de Mantenimiento</span>
                                            </Link>
                                        </li>

                                    </ul>
                                )}


                            </li>

                            <li style={getLiStyle(3, location, [9])} className={`LISTA__SUBUL ${location.pathname.startsWith('/productos') ? 'active' : ''}`} onClick={handleSubMenuToggleProductos}>

                                <img className='ICONO__MENU' src={iconSrcProductos} alt='icono productos' />

                                <span style={getSpanStyleProductos(location)} className={`SPAN__TOP ${location.pathname.startsWith('/productos') ? 'active' : ''}`} onClick={handleSubMenuToggleProductos}>Productos</span>

                                {isSubMenuVisibleProductos && (
                                    <ul className="SUB__UL productos">
                                        <li style={getSubListStyle(location, '/productos/epps')}>

                                            <Link to="/productos/epps" onClick={handleItemClick}>
                                                <img className='ICONO__MENU' src={iconSrcEPP} alt='icono productos' />
                                                <span>Equipos de Protección Personal</span>
                                            </Link>
                                        </li>

                                        <li style={getSubListStyle(location, '/productos/contenedores-ecologicos')}>
                                            <Link to="/productos/contenedores-ecologicos" onClick={handleItemClick}>
                                                <img className='ICONO__MENU' src={iconSrcContenedor} alt='icono productos' />
                                                <span>Contenedores Ecológicos</span>
                                            </Link>
                                        </li>
                                    </ul>
                                )}
                            </li>

                            <li style={getLiStyle(4, location, [9])} className='LI__LINK'>
                                <Link to='/proyectos' className='AYM__div__nav__ul__li' onClick={handleItemClick}>
                                    <img className='ICONO__MENU' src={iconSrcProyectos} alt='Icono__Proyectos' />
                                    <span>Proyectos</span>
                                </Link>
                            </li>

                            <li style={getLiStyle(5, location, [11])} className='LI__LINK'>
                                <Link to='/blog' onClick={handleItemClick}>
                                    <img className='ICONO__MENU' src={iconSrcBlog} alt='icono blog' />
                                    <span>Blog</span>
                                </Link>
                            </li>

                            <li style={getLiStyle(6, location, [12])} className='LI__LINK END'>
                                <Link to='/contacto' onClick={handleItemClick}>
                                    <img className='ICONO__MENU' src={iconSrcContacto} alt='icono contacto' />
                                    <span>Contacto</span>
                                </Link>
                            </li>
                        </ul>
                    </div>



                    <div className="footer__AYM__HOME">



                        <ul className='menu__footer__AYM__HOME '>
                            <li>
                                <Link to='/about' onClick={handleItemClick}>
                                    <span>Acerca de A&M CONTRATISTAS</span>
                                </Link>

                            </li>
                            <li>
                                <Link to='/politica-de-privacidad' onClick={handleItemClick}>
                                    <span>Política de privacidad</span>
                                </Link>

                            </li>
                            <li>© 2023 A&M TÉCNICOS INGENIEROS SAC</li>
                            <li>
                                <span>Desarollado por</span>
                                <Link to='https://aympaginasweb.com/' onClick={handleItemClick}>
                                    <span>  A&M Paginas Web</span>
                                </Link>

                            </li>
                            <li>Lima - Perú</li>
                        </ul>
                    </div>




                </section>
            </div>











        </section >

    );
}

export default Home;
