import React, { useState, useEffect, useCallback } from "react";
import "./contenedores.css";
import Tuercasm from '../../../aym666/galery/tuercas/Productos/epp/tuercas';

import ContenedoresIMG from '../../../recursos/ARRAY/CONTENEDORES/ContenedoresIMG';
const titulosContenedores = [
    'Contenedor metálico desde 5 m3 de capacidad',
    'Contenedor plástico de 2 ruedas',
    'Contenedor plástico desde 5 m3 de capacidad',
    'Contenedor plástico de 2 ruedas desde 80 Lts',
    'Botes de acero inoxidable',
    'Multiples capacidades en contenedores plásticos',
    'Contenedor hermético para residuos peligrosos',
    'Contenedor plástico cilíndrico desde 5 m3 de capacidad',
    'Botes plásticos con balancin desde 20 a 80 Lts',
    'Bote plástico con pedal desde 16 Lts',



    // Agrega más títulos según sea necesario
];


const Contenedores = () => {

    const [rightClick, setRightClick] = useState(false);
    const handleContextMenu = e => {
        e.preventDefault();
        setRightClick(true);
    };

    const url = 'https://wa.me/+51913280309';

    const handleComprarClick = () => {
        window.open(url, '_blank');
    };









    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isHovered] = useState(false);



    useEffect(() => {
        const interval = setInterval(() => {
            if (!isHovered) {
                setCurrentImageIndex((currentImageIndex + 1) % Tuercasm.length);
            }
        }, 1666);

        return () => clearInterval(interval);
    }, [currentImageIndex, isHovered]);












    const [activeImageIndex, setActiveImageIndex] = useState(null);

    const openViewer = (index) => {
        setActiveImageIndex(index);
    };

    const closeViewer = () => {
        setActiveImageIndex(null);
    };


    const handleKeyPress = useCallback((event) => {
        if (event.key === "Escape") {
            closeViewer();
        }
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            handleKeyPress(event);
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyPress]);







    return (
        <section className='AYM__CONTRATISTAS__PRODUCTOS__CONTENEDORES'>
            <div className='AYM__CONTRATISTAS__SECTION__PRODUCTOS__CONTENEDORES'>
                <div className='banner__PRODUCTOS__CONTENEDORES'>
                    <div className='elemento__banner__PRODUCTOS__CONTENEDORES'>
                    </div>
                </div>

                <div className='encabezado__PRODUCTOS__CONTENEDORES'>
                    <div className='encabezado__PRODUCTOS__CONTENEDORES__h3'>
                        <h3>Contenedores Ecológicos</h3>
                    </div>

                    <div className='PRODUCTOS__CONTENEDORES__P'>
                        <p>
                            Adquiere contenedores ecológicos de <strong>primera calidad</strong> y contribuye al cuidado del medio ambiente en tu empresa.

                        </p>
                    </div>
                </div>







                <div className="proyectos-grid">
                    {ContenedoresIMG.map((proyecto, index) => (
                        <div className="proyecto" key={index}>
                            <img
                                className="proyecto-img"
                                onClick={() => openViewer(index)}
                                src={proyecto}
                                alt={`Proyecto ${index + 1}`}
                                onContextMenu={handleContextMenu}

                            />
                            {rightClick && null}
                            <h3 className="proyecto-title">{titulosContenedores[index]}</h3>

                        </div>
                    ))}
                </div>



                {activeImageIndex !== null && (
                    <div className="image-viewer" onClick={closeViewer}>
                        <div className="image-viewer-content">
                            <img src={ContenedoresIMG[activeImageIndex]} alt={`Proyecto ${activeImageIndex + 1}`} onContextMenu={handleContextMenu} />
                            {rightClick && null}
                        </div>
                    </div>
                )}









            </div >




            <div className="div__comprar__contenedores">
                <button className="btn__comprar__contenedores" onClick={handleComprarClick}>
                    Cotizar
                </button>
            </div>
        </section >
    );
};

export default Contenedores;

