import React from 'react';
import "./atencionalcliente.css";
import { Link } from "react-router-dom";
import iconos from '../../../icon/iconos'

function Contactosub1() {



    const handleItemClick = () => {
        window.scrollTo(0, 0);
    };





    return (
        <section className='section__atencionalcliente'>
            <div className='div__atencionalcliente'>

                <div className='retorno__atencionalcliente'>

                    <Link to="/" className="btn__subcontacto__AYM" onClick={handleItemClick}>
                        Inicio
                    </Link>
                    <img src={iconos[8]} alt="Atras" />
                    <Link to="/contacto" className="btn__subcontacto__AYM" onClick={handleItemClick}>
                        Contacto
                    </Link>
                    <img src={iconos[8]} alt="Atras" />
                    <span>
                        Atención al cliente
                    </span>

                </div>




                <div className='subcontacto__div__box3'>

                    <h2 className='subcontacto__div__box3__h2'>
                        La excelencia en atención al cliente es lo que nos define
                    </h2>


                    <div>
                        <p>La satisfacción del cliente es nuestra prioridad número uno</p>
                    </div>

                    <br />



                    <p>
                        En A & M Contratistas, entendemos que la satisfacción del cliente es
                        primordial, y nos esforzamos por brindar un servicio
                        excepcional que garantice que cada interacción con nuestros
                        clientes sea una experiencia agradable y exitosa.
                    </p>
                    <br />

                    <p>
                        Para lograr esto, nuestro equipo de atención al cliente está dedicado
                        a comprender las necesidades y expectativas de nuestros clientes,
                        y a ofrecer soluciones personalizadas que se adapten a sus
                        necesidades. Nuestros representantes de atención al cliente
                        están disponibles en todo momento para ayudar y resolver
                        cualquier problema que pueda surgir. Trabajamos arduamente
                        para garantizar que cada cliente se sienta valorado y
                        satisfecho con nuestros servicios.</p>


                    <div className='subcontacto__div__box3__info'>
                        <ul>
                            <li>
                                <h3>Escríbenos:</h3>
                            </li>

                            <li>
                                <a href="mailto:info@aymcontratista.com">
                                    info@aymcontratista.com
                                </a>
                            </li>


                            <li>
                                <a href="mailto:operaciones@aymcontratista.com" >
                                    operaciones@aymcontratista.com
                                </a>
                            </li>

                            <li>
                                <a href="mailto:logistica@aymcontratista.com">
                                    logistica@aymcontratista.com
                                </a>
                            </li>
                        </ul>



                        <ul>
                            <li>
                                <h3>Contáctanos por WhatsApp</h3>
                            </li>




                            <li>

                                <a href="https://wa.me/51963245490?text=Atención%20al%20cliente" target="_blank" rel="noreferrer">
                                    <img src={iconos[16]} alt="Atras" />
                                    913 280 309
                                </a>
                            </li>


                        </ul>

                    </div>
                </div>


            </div>






        </section>



    );

}

export default Contactosub1;