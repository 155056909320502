import adm from './AYMCONTRATISTASADM.webp';
import amb from './AYMCONTRATISTASAMB.webp';
import pry from './AYMCONTRATISTASPRY.webp';
import mnt from './AYMCONTRATISTASMNT.webp';
import sgi from './AYMCONTRATISTASSGI.webp';

import Info from './AYM-INFOSERVICIOS.webp';
import Cliente from './AYM-ATENCIONCLIENTE.webp';
import Rrhh from './AYM-RRHH.webp';

const AYM999333 = [
    adm, amb, pry, sgi, mnt, Info, Cliente, Rrhh
];
export default AYM999333;