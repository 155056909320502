import React, { useState } from 'react';
import './articulo.css';
import ArticuloWEBP from '../../../recursos/ARRAY/ARTICULO/ArticuloWEBP';




const Articulo = ({ titulo, fechaPublicacion, contenido }) => {

    const [rightClick, setRightClick] = useState(false);
    const handleContextMenu = (event) => {
        event.preventDefault();
        setRightClick(true);
    };



    return (
        <section className='section__articulo'>
            <div className="div__articulo articulo01">
                <h2 className='articulo__h2'>¿Cuáles son las normativas ambientales aplicables a las empresas que generan emisiones?{titulo}</h2>
                <p className='articulo__fecha'>26 de abril de 2023 {fechaPublicacion}</p>


                <img className='articulo__img' onContextMenu={handleContextMenu} src={ArticuloWEBP[0]} alt='Consultoría Administrativa' />
                {rightClick && null}


                <p className='articulo__p'>En A&M Contratistas, estamos comprometidos con la protección
                    del medio ambiente y el cumplimiento de las <strong>normativas ambientales </strong>
                    en el Perú. Sabemos que generar emisiones
                    conlleva una responsabilidad importante. En este artículo,
                    queremos ofrecerte información valiosa sobre las normativas
                    ambientales que debes cumplir en el Perú si generas emisiones
                    en tu empresa, y te brindaremos consejos y medidas que puedes
                    tomar para asegurarte de cumplir con estas regulaciones y
                    contribuir a un entorno más sostenible.{contenido}</p>

                <p className='articulo__p'>En el contexto de las empresas que generan emisiones, es fundamental conocer las normativas ambientales aplicables que buscan regular y controlar el impacto de dichas emisiones en el entorno. A continuación, exploraremos las principales normativas ambientales que deben tener en cuenta estas empresas y las implicaciones que conllevan en términos de sostenibilidad y responsabilidad ambiental.</p>


                <h3 className='articulo__h3'>Ley General del Ambiente:</h3>
                <p className='articulo__p'>Esta ley establece el marco legal general que regula el impacto ambiental de las empresas. Las empresas deben cumplir con sus responsabilidades y obligaciones en relación con las emisiones, siguiendo las directrices establecidas en la <a className='articulo__a' href="https://www.minam.gob.pe/wp-content/uploads/2013/06/ley-general-del-ambiente.pdf" target="_blank" rel="noopener noreferrer">Ley General del Ambiente - Ley Nº 28611</a>.</p>

                <h3 className='articulo__h3'>Normas de calidad ambiental:</h3>
                <p className='articulo__p'>Las normas de calidad ambiental establecen los límites y estándares para las emisiones contaminantes. Las empresas deben asegurarse de que sus emisiones cumplan con los límites establecidos para cada contaminante específico.
                    En el Perú, puedes consultar las <a className='articulo__a' href="https://www.minam.gob.pe/calidadambiental/normativa/" target="_blank" rel="noopener noreferrer">normas de calidad ambiental</a> emitidas por el <a className='articulo__a' href="https://www.gob.pe/minam" target="_blank" rel="noopener noreferrer">Ministerio del Ambiente</a>  para obtener más información sobre los requisitos específicos que debes cumplir.
                </p>


                <h3 className='articulo__h3'>Evaluación de Impacto Ambiental (EIA):</h3>
                <p className='articulo__p'>En ciertos casos, las empresas que generan emisiones deben realizar una Evaluación de Impacto Ambiental (EIA) para evaluar el impacto de sus actividades en el medio ambiente. Esta evaluación permite identificar los posibles efectos negativos y tomar medidas para mitigarlos.</p>


                <h3 className='articulo__h3'>¿Cuál es la importancia de cumplir con las normativas ambientales?</h3>
                <p className='articulo__p'>Cumplir con las normativas ambientales es de vital importancia tanto para las empresas como para el medio ambiente. Estas regulaciones buscan proteger los recursos naturales, minimizar la contaminación y garantizar un desarrollo sostenible. Al cumplir con estas normativas, las empresas contribuyen a la preservación del entorno, evitan sanciones legales, fortalecen su reputación corporativa y demuestran su compromiso con la responsabilidad ambiental.</p>



                <h3 className='articulo__h3'>Protección del medio ambiente:</h3>
                <p className='articulo__p'>El cumplimiento de las normativas ambientales contribuye a la protección del medio ambiente al reducir los impactos negativos de las emisiones contaminantes.
                    Esto ayuda a preservar los <a className='articulo__a' href="https://www.minam.gob.pe/diversidadbiologica/nuestras-lineas-de-intervencion/direccion-de-conservacion-sostenible-de-ecosistemas-y-especies/ecosistemas/" target="_blank" rel="noopener noreferrer">ecosistemas</a> y
                    la <a className='articulo__a' href="https://www.inei.gob.pe/media/MenuRecursivo/publicaciones_digitales/Est/Lib1140/cap02.pdf" target="_blank" rel="noopener noreferrer">biodiversidad</a> en el Perú.</p>


                <h3 className='articulo__h3'>Sostenibilidad y responsabilidad social:</h3>
                <p className='articulo__p'>Cumplir con las normativas ambientales es fundamental para la sostenibilidad de las empresas. Al adoptar prácticas respetuosas con el medio ambiente, las empresas demuestran su compromiso con la responsabilidad social corporativa y contribuyen
                    al <a className='articulo__a' href="https://www.minam.gob.pe/wp-content/uploads/2016/07/ODS-FINAL210716.pdf" target="_blank" rel="noopener noreferrer">desarrollo sostenible</a>.</p>



                <h3 className='articulo__h3'>Evitar sanciones y consecuencias legales:</h3>
                <p className='articulo__p'>El incumplimiento de las normativas ambientales puede dar lugar a <a className='articulo__a' href="https://www.oefa.gob.pe/conoce-los-tipos-de-infracciones-y-escala-de-sanciones-que-impone-el-oefa-ante-el-incumplimiento-de-las-obligaciones-ambientales/webmaster/" target="_blank" rel="noopener noreferrer">sanciones y multas.</a>
                    Cumplir con estas regulaciones ayuda a evitar consecuencias legales y reputacionales negativas para las empresas.</p>


            </div>
        </section>
    );
};

export default Articulo;
