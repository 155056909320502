import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useLocation } from 'react-router-dom';
import IconNav from '../iconNav/iconNav';
import './aymNav.css';
import "./social.css";
import '../componentes/WhatsappAYM.css';
import WSP from '../img/AYM__WSP.svg';


function AymNav() {




















    const NavRef = useRef(null);
    const [NAV__AYM, setNavFixed] = useState(false);
    const rem = 16;

    useEffect(() => {
        const handleScroll = () => {
            const { current: nav } = NavRef;
            const navOffsetTop = nav.offsetTop;
            if (window.scrollY > navOffsetTop + 1 * rem) {
                setNavFixed(true);
            } else {
                setNavFixed(false);
            }
        };

        handleScroll();

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [NavRef]);




    const phoneNumber = '+51913280309';

    const handleClick = (e) => {
        e.preventDefault();
        window.open(`https://wa.me/${phoneNumber}`, '_blank');
    };


















    const [rightClick, setRightClick] = useState(false);
    const handleContextMenu = (event) => {
        event.preventDefault();
        setRightClick(true);
    };




    const handleItemClick = () => {

        window.scrollTo(0, 0);
    };









    const location = useLocation();

    const iconInicio = location.pathname === '/' ? 1 : 7;
    const iconServicios = location.pathname === '/servicios' ||
        location.pathname === '/servicios/consultoria-administrativa' ||
        location.pathname === '/servicios/consultoria-ambiental' ||
        location.pathname === '/servicios/construccion-y-proyectos' ||
        location.pathname === '/servicios/sst' ||
        location.pathname === '/servicios/mantenimiento' ? 2 : 8;

    const iconADM = location.pathname === '/servicios/consultoria-administrativa' ? 13 : 13;
    const iconAmbiental = location.pathname === '/servicios/consultoria-ambiental' ? 14 : 14;
    const iconConstruccion = location.pathname === '/servicios/construccion-y-proyectos' ? 15 : 15;
    const iconSSOMA = location.pathname === '/servicios/sst' ? 16 : 16;
    const iconMantenimiento = location.pathname === '/servicios/sst' ? 17 : 17;

    const iconProductos = location.pathname === '/productos/epps' || location.pathname === '/productos/contenedores-ecologicos' ? 3 : 9;
    const iconEPP = location.pathname === '/productos/epps' ? 18 : 18;
    const iconContenedor = location.pathname === '/productos/contenedores-ecologicos' ? 19 : 19;


    const iconProyectos = location.pathname === '/proyectos' ? 4 : 10;
    const iconBlog = location.pathname === '/blog' ? 5 : 11;
    const iconContacto = location.pathname === '/contacto' ? 6 : 12;


    const iconSrcInicio = IconNav[iconInicio];
    const iconSrcServicios = IconNav[iconServicios];

    const iconSrcADM = IconNav[iconADM];
    const iconSrcAmbiental = IconNav[iconAmbiental];
    const iconSrcConstruccion = IconNav[iconConstruccion];
    const iconSrcSSOMA = IconNav[iconSSOMA];
    const iconSrcMantenimiento = IconNav[iconMantenimiento];


    const iconSrcProductos = IconNav[iconProductos];
    const iconSrcEPP = IconNav[iconEPP];
    const iconSrcContenedor = IconNav[iconContenedor];

    const iconSrcProyectos = IconNav[iconProyectos];
    const iconSrcBlog = IconNav[iconBlog];
    const iconSrcContacto = IconNav[iconContacto];





    const getLiStyle = (iconIndex, location, activeIndexes) => {
        const liStyle = {
            backgroundImage: 'none',
            backgroundPosition: 'initial',
            backgroundRepeat: 'initial',
            backgroundSize: 'initial',
        };


        if (activeIndexes.includes(iconIndex)) {
            liStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
            liStyle.fontWeight = 'bold';
        } else if (location.pathname === '/') {
            if (iconIndex === 1) {
                liStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
                liStyle.fontWeight = 'bold';
            }


        } else if (location.pathname === '/productos') {
            if (iconIndex === 3) {
                liStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
                liStyle.fontWeight = 'bold';
            }
        } else if (location.pathname === '/proyectos') {
            if (iconIndex === 4) {
                liStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
                liStyle.fontWeight = 'bold';
            }
        } else if (location.pathname === '/blog') {
            if (iconIndex === 5) {
                liStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
                liStyle.fontWeight = 'bold';

            }
        } else if (location.pathname === '/contacto') {
            if (iconIndex === 6) {
                liStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
                liStyle.fontWeight = 'bold';
            }
        }

        return liStyle;
    };

    function getSpanStyle(location) {
        const spanStyle = {};

        const serviciosRoutes = [
            '/servicios/consultoria-administrativa',
            '/servicios/consultoria-ambiental',
            '/servicios/construccion-y-proyectos',
            '/servicios/sst',
            '/servicios/mantenimiento',

        ];

        if (serviciosRoutes.includes(location.pathname)) {
            spanStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
            spanStyle.fontWeight = 'bold';

        }

        return spanStyle;
    }


    function getSpanStyleProductos(location) {
        const spanStyleProductos = {};

        const ProductosRoutes = [
            '/productos/epps',
            '/productos/contenedores-ecologicos',
        ];

        if (ProductosRoutes.includes(location.pathname)) {
            spanStyleProductos.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';
            spanStyleProductos.fontWeight = 'bold';

        }

        return spanStyleProductos;
    }




    function getIconStyle(location) {
        const iconStyle = {};

        const serviciosRoutes = [
            '/servicios/consultoria-administrativa',
            '/servicios/consultoria-ambiental',
            '/servicios/construccion-y-proyectos',
            '/servicios/sst',
            '/servicios/mantenimiento',

        ];

        if (serviciosRoutes.includes(location.pathname)) {
            iconStyle.filter = 'drop-shadow(1px 1px 0.6px rgba(14, 0, 87, 0.87))';


        }

        return iconStyle;
    }


    function getSubListStyle(location, currentRoute) {
        const subListStyle = {};

        if (location.pathname === currentRoute) {
            subListStyle.fontWeight = 'bold';
            subListStyle.background = 'rgba(1, 238, 255, 0.263)';
            subListStyle.paddingLeft = '5px';
            subListStyle.paddingRight = '5px';
            subListStyle.borderRadius = '20px';

        }

        return subListStyle;
    }



    const [isSubMenuVisibleServicios, setSubMenuVisibleServicios] = useState(false);
    const [isSubMenuVisibleProductos, setSubMenuVisibleProductos] = useState(false);

    const serviciosTimerRef = useRef(null);
    const productosTimerRef = useRef(null);

    const resetTimer = useCallback((timerRef, setIsSubMenuVisible) => {
        clearTimeout(timerRef.current);
        setIsSubMenuVisible(true);
        timerRef.current = setTimeout(() => {
            setIsSubMenuVisible(false);
        }, 60000);
    }, []);

    useEffect(() => {
        const serviciosTimer = serviciosTimerRef.current;
        const productosTimer = productosTimerRef.current;

        return () => {
            clearTimeout(serviciosTimer);
            clearTimeout(productosTimer);
        };
    }, []);

    const handleSubMenuToggleServicios = useCallback(() => {
        setSubMenuVisibleServicios((prevState) => !prevState);
        resetTimer(serviciosTimerRef, setSubMenuVisibleServicios);
    }, [resetTimer]);

    const handleSubMenuToggleProductos = useCallback(() => {
        setSubMenuVisibleProductos((prevState) => !prevState);
        resetTimer(productosTimerRef, setSubMenuVisibleProductos);
    }, [resetTimer]);




    const [expandedFooter, setExpanded] = useState(false);

    const toggleMenuFooter = () => {
        setExpanded(!expandedFooter);
    };













    return (
        <div className='MENU__AYM'>

            <div>
                <a className="btn-WSP" href={`https://wa.me/${phoneNumber}`} onClick={handleClick}>
                    <img src={WSP} alt="Contactar por WhatsApp" />
                </a>
            </div>





            <nav className='NAV__LOGO'>
                <div id="LogoAyMContratistas">
                    <Link to='/' onClick={handleItemClick}>
                        <img className='ICONO__LOGO' src={IconNav[0]} alt='Icono__Inicio' onContextMenu={handleContextMenu} />
                        {rightClick && null}
                        <h1 className="ICONO__LOGO__H1">A&M CONTRATISTAS</h1>
                    </Link>
                </div>
            </nav>


            <nav ref={NavRef} className={NAV__AYM ? 'NAV__AYM' : 'NAV__AYM'}>







                <ul className='UL__AYM'>




                    <li style={getLiStyle(1, location, [7])} className='LI__LINK home' >
                        <Link to='/' className='AYM__div__nav__ul__li' onClick={handleItemClick}>
                            <img className='ICONO__MENU' src={iconSrcInicio} alt='icono inicio' />
                            <span>Inicio</span>
                        </Link>

                    </li>

                    <li style={getLiStyle(2, location, [8, 9, 10, 11, 12])} className={`LISTA__SUBUL ${location.pathname.startsWith('/servicios') ? 'active' : ''}`} onClick={handleSubMenuToggleServicios}>

                        <img style={getIconStyle(location)} className='ICONO__MENU' src={iconSrcServicios} alt='icono servicios' />
                        <span style={getSpanStyle(location)} className={`SPAN__TOP ${location.pathname.startsWith('/servicios') ? 'active' : ''}`} onClick={handleSubMenuToggleServicios}>Servicios</span>

                        {isSubMenuVisibleServicios && (
                            <ul className='SUB__UL servicios'>
                                <li style={getSubListStyle(location, '/servicios/consultoria-administrativa')}>

                                    <Link to='/servicios/consultoria-administrativa' aria-label='Este es un enlace al servicio de consultoria-administrativa' onClick={handleItemClick}>
                                        <img style={getIconStyle(location)} className='ICONO__MENU' src={iconSrcADM} alt='icono servicios' />
                                        <span>Consultoría Administrativa</span>
                                    </Link>
                                </li>

                                <li style={getSubListStyle(location, '/servicios/consultoria-ambiental')}>
                                    <Link to='/servicios/consultoria-ambiental' aria-label='Este es un enlace al servicio de consultoria-ambiental' onClick={handleItemClick}>
                                        <img style={getIconStyle(location)} className='ICONO__MENU' src={iconSrcAmbiental} alt='icono servicios' />
                                        <span> Consultoría Ambiental</span>
                                    </Link>
                                </li>

                                <li style={getSubListStyle(location, '/servicios/construccion-y-proyectos')}>
                                    <Link to='/servicios/construccion-y-proyectos' aria-label='Este es un enlace al servicio de construccion-y-proyectos' onClick={handleItemClick}>
                                        <img style={getIconStyle(location)} className='ICONO__MENU' src={iconSrcConstruccion} alt='icono servicios' />
                                        <span> Construcción y Proyectos</span>
                                    </Link>
                                </li>

                                <li style={getSubListStyle(location, '/servicios/sst')}>
                                    <Link to='/servicios/sst' aria-label='Este es un enlace al servicio de seguridad-y-salud-en-el-trabajo' onClick={handleItemClick}>
                                        <img style={getIconStyle(location)} className='ICONO__MENU' src={iconSrcSSOMA} alt='icono servicios' />
                                        <span>Seguridad y Salud en el Trabajo</span>
                                    </Link>
                                </li>

                                <li style={getSubListStyle(location, '/servicios/mantenimiento')}>
                                    <Link to='/servicios/mantenimiento' aria-label='Este es un enlace al servicio de mantenimiento' onClick={handleItemClick}>
                                        <img style={getIconStyle(location)} className='ICONO__MENU' src={iconSrcMantenimiento} alt='icono servicios' />
                                        <span>Servicios de Mantenimiento</span>
                                    </Link>
                                </li>

                            </ul>
                        )}


                    </li>

                    <li style={getLiStyle(3, location, [9])} className={`LISTA__SUBUL ${location.pathname.startsWith('/productos') ? 'active' : ''}`} onClick={handleSubMenuToggleProductos}>

                        <img className='ICONO__MENU' src={iconSrcProductos} alt='icono productos' />

                        <span style={getSpanStyleProductos(location)} className={`SPAN__TOP ${location.pathname.startsWith('/productos') ? 'active' : ''}`} onClick={handleSubMenuToggleProductos}>Productos</span>

                        {isSubMenuVisibleProductos && (
                            <ul className="SUB__UL productos">
                                <li style={getSubListStyle(location, '/productos/epps')}>

                                    <Link to="/productos/epps" onClick={handleItemClick}>
                                        <img className='ICONO__MENU' src={iconSrcEPP} alt='icono productos' />
                                        <span>Equipos de Protección Personal</span>
                                    </Link>
                                </li>

                                <li style={getSubListStyle(location, '/productos/contenedores-ecologicos')}>
                                    <Link to="/productos/contenedores-ecologicos" onClick={handleItemClick}>
                                        <img className='ICONO__MENU' src={iconSrcContenedor} alt='icono productos' />
                                        <span>Contenedores Ecológicos</span>
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </li>

                    <li style={getLiStyle(4, location, [9])} className='LI__LINK'>
                        <Link to='/proyectos' className='AYM__div__nav__ul__li' onClick={handleItemClick}>
                            <img className='ICONO__MENU' src={iconSrcProyectos} alt='Icono__Proyectos' />
                            <span>Proyectos</span>
                        </Link>
                    </li>

                    <li style={getLiStyle(5, location, [11])} className='LI__LINK'>
                        <Link to='/blog' onClick={handleItemClick}>
                            <img className='ICONO__MENU' src={iconSrcBlog} alt='icono blog' />
                            <span>Blog</span>
                        </Link>
                    </li>

                    <li style={getLiStyle(6, location, [12])} className='LI__LINK END'>
                        <Link to='/contacto' onClick={handleItemClick}>
                            <img className='ICONO__MENU' src={iconSrcContacto} alt='icono contacto' />
                            <span>Contacto</span>
                        </Link>
                    </li>



                </ul>







            </nav >


            <div className="footer__AYM">





                <button className="Mas__footer" onClick={toggleMenuFooter}>
                    <img src={IconNav[20]} alt="Icono Barra" />
                    <span class="Mas__footer__span">Más</span>
                </button>

                <ul className={`menu__footer__AYM ${expandedFooter ? 'show__footer' : ''}`}>
                    <li>
                        <Link to='/about' onClick={handleItemClick}>
                            <span>Acerca de A&M CONTRATISTAS</span>
                        </Link>

                    </li>



                    <li>
                        <Link to='/politica-de-privacidad' onClick={handleItemClick}>
                            <span>Política de privacidad</span>
                        </Link>

                    </li>
                    <li>© 2023 A&M TÉCNICOS INGENIEROS SAC</li>
                    <li>
                        <span>Desarollado por</span>
                        <Link to='https://aympaginasweb.com/' onClick={handleItemClick}>
                            <span>  A&M Paginas Web</span>
                        </Link>

                    </li>
                    <li>Lima - Perú</li>
                </ul>
            </div>
        </div >



    );

}

export default AymNav;