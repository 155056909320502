import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';

import './App.css';

import AymNAv from './componentes/aymNav';
import Home from './componentes/home';
import Whatsapp from './componentes/WhatsappAYM';
import './componentes/WhatsappAYM.css';





import Contacto from "./componentes/contacto/contacto";
import Subcontacto1 from "./componentes/contacto/atencion-al-cliente/index";
import Subcontacto2 from "./componentes/contacto/trabaja-con-nosotros/index";
import Subcontacto3 from "./componentes/contacto/info-servicios/index";



import ContenedoresPro from "./componentes/productos/contenedores-ecologicos/index";
import Proyectos from "./componentes/proyectos/proyectos";
import About from "./componentes/nosotros";


import Blog from "./componentes/blog/blog";
import { Helmet } from 'react-helmet';
const Administracion = React.lazy(() => import('./componentes/servicios/consultoria-administrativa/index'));
const Ambiental = React.lazy(() => import('./componentes/servicios/consultoria-ambiental/index'));
const Construccion = React.lazy(() => import('./componentes/servicios/construccion-y-proyectos/index'));
const Sst = React.lazy(() => import('./componentes/servicios/sst/index'));
const Mantenimiento = React.lazy(() => import('./componentes/servicios/mantenimiento/index'));
const EppsPro = React.lazy(() => import('./componentes/productos/epp/index'));
const EppsZPT01A = React.lazy(() => import('./componentes/productos/epp/20833/index'));

const PoliticadePrivacidad = React.lazy(() => import("./componentes/PoliticaPrivacidad/PoliticaPrivacidad"));




function App() {
  return (
    <>

      <Helmet
        contentSecurityPolicy="
          default-src 'self';
          script-src 'self' 'unsafe-inline';
          style-src 'self' 'unsafe-inline';
          img-src 'self' data:;
          font-src 'self' data:;
          connect-src 'self';
          media-src 'self';
          object-src 'none';
          frame-src 'none';
        "
      />

      <Router>

        {<Whatsapp />}
        {<AymNAv />}

        <Suspense fallback={<div>Cargando...</div>}>
          <Routes>

            <Route path="/" element={<Home />} exact></Route>


            <Route path="/servicios/consultoria-administrativa" element={<React.Suspense ><Administracion /></React.Suspense>}></Route>
            <Route path="/servicios/consultoria-ambiental" element={<React.Suspense ><Ambiental /></React.Suspense>}></Route>
            <Route path="/servicios/construccion-y-proyectos" element={<React.Suspense ><Construccion /></React.Suspense>}></Route>
            <Route path="/servicios/sst" element={<React.Suspense ><Sst /></React.Suspense>}></Route>
            <Route path="/servicios/mantenimiento" element={<React.Suspense ><Mantenimiento /></React.Suspense>}></Route>

            <Route path="/productos/epps" element={<React.Suspense ><EppsPro /></React.Suspense>}></Route>
            <Route path="/productos/epps/20833" element={<React.Suspense ><EppsZPT01A /></React.Suspense>}></Route>



            <Route path="/productos/contenedores-ecologicos" element={<ContenedoresPro />}></Route>

            <Route path="/contacto" element={<Contacto />}></Route>
            <Route path="/contacto/atencion-al-cliente" element={<Subcontacto1 />}></Route>
            <Route path="/contacto/rrhh" element={<Subcontacto2 />}></Route>
            <Route path="/contacto/solicita-informacion-de-nuestros-servicios" element={<Subcontacto3 />}></Route>

            <Route path="/politica-de-privacidad" element={<React.Suspense ><PoliticadePrivacidad /></React.Suspense>}></Route>


            <Route path="/proyectos" element={<Proyectos />}></Route>
            <Route path="/blog" element={<Blog />}></Route>

            <Route path="/about" element={<About />}></Route>









          </Routes>


        </Suspense>




      </Router >




    </>


  );
}

export default App;