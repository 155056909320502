import PRY__00__WEBP00 from './PRY__00__WEBP00.webp';
import PRY__01__WEBP01 from './PRY__01__WEBP01.webp';
import PRY__02__WEBP02 from './PRY__02__WEBP02.webp';
import PRY__03__WEBP03 from './PRY__03__WEBP03.webp';
import PRY__04__WEBP04 from './PRY__04__WEBP04.webp';
import PRY__05__WEBP05 from './PRY__05__WEBP05.webp';
import PRY__06__WEBP06 from './PRY__06__WEBP06.webp';
import PRY__07__WEBP07 from './PRY__07__WEBP07.webp';


const ProyectosWEBP = [
    PRY__00__WEBP00,
    PRY__01__WEBP01,
    PRY__02__WEBP02,
    PRY__03__WEBP03,
    PRY__04__WEBP04,
    PRY__05__WEBP05,
    PRY__06__WEBP06,
    PRY__07__WEBP07,
];
export default ProyectosWEBP;