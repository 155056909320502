import React from 'react';
import "./trabajaconnosotros.css";
import { Link } from "react-router-dom";
import iconos from '../../../icon/iconos'

function Contactosub1() {



    const handleItemClick = () => {
        window.scrollTo(0, 0);
    };





    return (
        <section className='section__trabajaconnosotros'>
            <div className='div__trabajaconnosotros'>

                <div className='retorno__trabajaconnosotros'>

                    <Link to="/" className="btn__subcontacto__AYM" onClick={handleItemClick}>
                        Inicio
                    </Link>
                    <img src={iconos[8]} alt="Atras" />
                    <Link to="/contacto" className="btn__subcontacto__AYM" onClick={handleItemClick}>
                        Contacto
                    </Link>
                    <img src={iconos[8]} alt="Atras" />
                    <span>
                        Trabaja con nosotros
                    </span>

                </div>



                <div className='subcontacto__div__box3'>

                    <h2 className='subcontacto__div__box3__h2'>
                        Únete a nuestro equipo
                    </h2>


                    <div>
                        <p>Si buscas una empresa que valore la excelencia en el trabajo y
                            el desarrollo profesional, únete a nuestro equipo de alto
                            rendimiento y demuestra tu talento en un ambiente de primer nivel</p>
                    </div>

                    <br />



                    <p>
                        En A&M Contratistas estamos comprometidos con nuestro equipo de trabajo,
                        y creemos que nuestro éxito se debe al talento, la dedicación y la pasión
                        de nuestros colaboradores.
                    </p>
                    <br />

                    <p>
                        Si eres una persona comprometida, proactiva y con ganas de aprender,
                        te invitamos a formar parte de nuestro equipo. En A&M Contratistas,
                        ofrecemos un ambiente de trabajo seguro y agradable, desde prácticas hasta oportunidades
                        de desarrollo profesional y un excelente paquete de beneficios,
                        para que puedas alcanzar tus metas y sentirte apoyado/a
                        en todo momento.</p>
                    <br />
                    <p>
                        ¡Únete al equipo de A&M Contratistas y haz crecer tu carrera profesional!
                    </p>





                    <div className='subcontacto__div__box3__info'>
                        <ul>
                            <li>
                                <h3>Envía tu CV a nuestro correo:</h3>
                            </li>

                            <li>
                                <a href="mailto:info@aymcontratista.com">
                                    rrhh@aymcontratista.com
                                </a>
                            </li>

                        </ul>




                    </div>
                </div>


            </div>






        </section>



    );

}

export default Contactosub1;